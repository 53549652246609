/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useQuery } from '@apollo/client';
import { ToastContext } from 'contexts/Toast';
import { useContext } from 'react';
import DwMedia from '../../../@types/dwMedia';

const GET_CLONED_MEDIAS_BY_ID = gql`
  query GetClonedMediasById($id: UUID!) {
    getClonedMediasById(id: $id) {
      id
      media_id
      media_url
      media_text
      media_source
      media_type
      media_qty_received
      media_qty_sent
      media_main
      subject
      interest_topic_id
      intent_tags
      concrete_tags
      concrete_tags_valences
      relevance
      source {
        name
      }
    }
  }
`;

export function useGetClonedMediasById(id: string): { data: DwMedia[]; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_CLONED_MEDIAS_BY_ID, {
    variables: { id },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: [] as DwMedia[], loading: false };
  }
  return { data: data ? data.getClonedMediasById : ([] as DwMedia[]), loading };
}
