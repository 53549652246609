import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import ContainerBs from 'react-bootstrap/Container';

import bgProfile01 from '../../assets/images/background/bg-profile-1.svg';
import bgProfile02 from '../../assets/images/background/bg-profile-2.svg';

import todosIcon from '../../assets/images/icon/tutorials/grid.svg';
import categoriesIcon from '../../assets/images/icon/tutorials/book.svg';
import brandsIcon from '../../assets/images/icon/profile/brands.svg';
import keywordsIcon from '../../assets/images/icon/profile/keywords.svg';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  background-image: url(${bgProfile01}), url(${bgProfile02});
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: left -1% bottom 5%, right 2% top 10%;
  min-height: calc(100vh - 60px);
  padding-top: 1rem;
`;

export const Container = styled(ContainerBs)``;

export const Header = styled(Row)`
  margin-bottom: 3rem;

  & .col-lg-12 {
    padding-left: 0;
  }
`;

export const Content = styled(Row)`
  & .col-lg-3 {
    padding-left: 0;
  }

  .nav {
    border: 1px solid ${({ theme }) => theme.colors.neutral[2]};
    border-radius: 4px;
    font-size: 18px;

    .nav-link {
      color: ${({ theme }) => theme.colors.neutral[8]};
      text-align: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &:before {
        content: '';
        width: 30px;
        height: 30px;
        margin-right: 8px;
        filter: grayscale(100%);
      }

      &.profile:before {
        background: no-repeat center url(${todosIcon});
      }

      &.categories:before {
        background: no-repeat center url(${categoriesIcon});
      }

      &.brands:before {
        background: no-repeat center url(${brandsIcon});
      }

      &.keywords:before {
        background: no-repeat center url(${keywordsIcon});
      }

      &.active {
        color: ${({ theme }) => theme.colors.support.blue};
        background-color: ${({ theme }) => theme.colors.neutral[2]};
        border-right: 4px solid ${({ theme }) => theme.colors.support.blue};

        &:before {
          filter: unset;
        }
      }
    }
  }
`;
