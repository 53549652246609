import FormControl from 'react-bootstrap/FormControl';
import ClearButton from 'react-bootstrap/Button';
import { InputGroup } from './styles';

function SearchField({
  placeholder,
  value,
  changeAction,
  clear,
}: {
  placeholder: string;
  value: string;
  changeAction: (text: string) => void;
  clear: () => void;
}): JSX.Element {
  return (
    <InputGroup>
      <div id="search-icon" />
      <FormControl
        id="inlineFormInputGroupUsername"
        placeholder={placeholder}
        value={value}
        onChange={(event) => changeAction(event.target.value)}
      />
      <ClearButton id="clear-field" onClick={clear} />
    </InputGroup>
  );
}

export default SearchField;
