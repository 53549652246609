import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import ContainerBs from 'react-bootstrap/Container';

export const Container = styled(ContainerBs)`
  padding-bottom: 1.5rem;
  padding-left: 0;
`;

export const TagsContainer = styled.div`
  font-family: 'Source Sans Pro', sans-serif;

  .ReactTags__tags {
    position: relative;
    padding: 8px 4px 18px 4px;
    border: 1px solid #dddddd;
    min-height: 185px;
    margin-bottom: 2rem;
  }

  .ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
  }

  /* Styles for the input */
  .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
    width: 100%;
  }

  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    border: 1px solid #eee;
    border: none;
    outline-width: 0;

    max-width: 500px;
    width: 100%;

    margin-top: 8px;

    font-size: 14px;
  }

  .ReactTags__editInput {
    border-radius: 1px;
  }

  .ReactTags__editTagInput {
    display: inline-flex;
  }

  .ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    padding: 4px 8px 4px 16px;
    border-radius: 4px;
    color: #2f80ed;
    background: #d5e6fb;
    border: none;

    font-size: 12px;
    display: inline-block;
  }

  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }

  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }

  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: #3c97ff;
    font-size: 16px;
  }
`;

export const TagsContent = styled.div``;

export const Content = styled(Row)``;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;

  & + & {
    margin-top: 10px;
  }

  select {
    max-width: 120px;
    max-height: 36px !important;
  }
`;
