import { useContext } from 'react';
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { ToastContext } from '../../../../contexts/Toast';
import Tutorial from '../../../../@types/tutorial';

type returnType = {
  updateTutorial: (
    options?:
      | MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>>
      | undefined
  ) => Promise<FetchResult<unknown, Record<string, unknown>, Record<string, unknown>>>;
  loading: boolean;
};

const UPDATE_TUTORIAL = gql`
  mutation UpdateTutorial($updateTutorialId: UUID, $tutorial: TutorialInput) {
    updateTutorial(id: $updateTutorialId, tutorial: $tutorial) {
      id
      category_id
      title
      description
      url
      created_at
      tutorial_category {
        id
        name
      }
    }
  }
`;

export function useUpdateTutorial(): returnType {
  const toastContext = useContext(ToastContext);

  const [updateTutorial, { loading }] = useMutation(UPDATE_TUTORIAL, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          Tutorials(existingTutorials = []) {
            cache.readFragment({
              id: `Tutorial:${data.updateTutorial.id}`,
              fragment: gql`
                fragment Tutorial on Tutorial {
                  id
                  category_id
                  title
                  description
                  url
                  created_at
                  tutorial_category {
                    id
                    name
                  }
                }
              `,
            });

            return existingTutorials.map((tutorialCategory: Tutorial) => {
              if (tutorialCategory.id === data.updateTutorialCategory.id) {
                return data.updateTutorialCategory;
              }

              return tutorialCategory;
            });
          },
        },
      });
    },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
    onCompleted: () => {
      toastContext.addToast({
        title: 'Sucesso',
        message: 'Categoria de tutorial editada com sucesso',
        type: 'success',
      });
    },
  });
  return { updateTutorial, loading };
}
