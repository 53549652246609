import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import { v4 } from 'uuid';
import { Toast } from './styles';
import ToastProps from '../../../@types/toast';

import sucessIcon from '../../../assets/images/icon/feedback/success.svg';
import errorIcon from '../../../assets/images/icon/feedback/error.svg';
import infoIcon from '../../../assets/images/icon/feedback/info.svg';
import alertIcon from '../../../assets/images/icon/feedback/alert.svg';

function FeedbackToast({ type, autohide, delay, title, message, key }: ToastProps): JSX.Element {
  const [show, setShow] = useState(true);
  return (
    <Toast
      className={type}
      onClose={() => setShow(false)}
      show={show}
      delay={delay}
      autohide={autohide}
      key={key}
    >
      <Toast.Body className="w-100 d-flex align-items-center">
        <div className="w-100 d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-center">
            {type === 'success' && <Image src={sucessIcon} alt="Sucesso" />}
            {type === 'error' && <Image src={errorIcon} alt="Erro" />}
            {type === 'info' && <Image src={infoIcon} alt="Informação" />}
            {type === 'alert' && <Image src={alertIcon} alt="Alerta" />}
          </div>
          <div id="text-container">
            <p>{title}</p>
            <p>{message}</p>
          </div>
        </div>
        <button type="button" className="btn" onClick={() => setShow(false)}>
          FECHAR
        </button>
      </Toast.Body>
    </Toast>
  );
}

FeedbackToast.defaultProps = {
  autohide: true,
  delay: 5000,
  key: v4().toString(),
  title: 'Erro',
  message: 'Ocorreu um erro inesperado',
  mediaId: '',
};

export default React.memo(FeedbackToast);
