import styled from 'styled-components';

export const H1Light = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H1 = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H1Bold = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H2Light = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H2 = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H2Bold = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H3Light = styled.h3`
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H3 = styled.h3`
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H3Bold = styled.h3`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H4Light = styled.h4`
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H4 = styled.h4`
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const H4Bold = styled.h4`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const P16 = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const P16SemiBold = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const P14 = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const P14SemiBold = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const P12 = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const P12SemiBold = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const L12 = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const L12SemiBold = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 0;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const BolderText = styled.span`
  font-weight: 700;
`;
