import { useGetTutorials } from 'hooks/graphql/Admin/Tutorials/useGetTutorials';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TutorialModal } from 'components/Tutorials/TutorialModal';
import Tutorial from '../../../@types/tutorial';
import { CardContainer, Container, Content, Description, Title, ThumbContainer, Infos } from './styles';
import playIcon from '../../../assets/images/icon/tutorials/play-icon.svg';

export default function Todos(): JSX.Element {
  const { data: tutorialsData } = useGetTutorials();
  const [tutorials, setTutorials] = useState<Tutorial[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState<Tutorial>({} as Tutorial);

  const { id } = useParams();

  useEffect(() => {
    if (!id || tutorialsData.length === 0) return;

    if (id === 'all') {
      setTutorials(tutorialsData);
      return;
    }

    setTutorials(tutorialsData.filter((tutorial) => tutorial?.tutorial_category?.id === id));
  }, [id, tutorialsData]);

  function handleCardClick(tutorial: Tutorial): void {
    setSelectedTutorial(tutorial);
    setIsModalVisible(true);
  }

  return (
    <>
      <Container>
        <Content>
          {tutorials.length > 0 ? (
            <>
              {tutorials?.map((tutorial) => (
                <CardContainer key={tutorial.id} onClick={() => handleCardClick(tutorial)}>
                  <ThumbContainer>
                    <img
                      className="thumb-img"
                      src={`https://i.ytimg.com/vi/${tutorial.url}/hqdefault.jpg`}
                      alt="Tutorial Thumbnail"
                    />
                    <img className="play-icon" src={playIcon} alt="Play Icon" />
                  </ThumbContainer>
                  <Infos>
                    {moment(tutorial.created_at).locale('pt-br').format('DD [de] MMM[.]')} |{' '}
                    {tutorial.tutorial_category?.name}
                  </Infos>
                  <Title>{tutorial.title}</Title>
                  <Description>{tutorial.description}</Description>
                </CardContainer>
              ))}
            </>
          ) : (
            <Infos>Nenhum tutorial encontrado nessa categoria.</Infos>
          )}
        </Content>
      </Container>
      <TutorialModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedTutorial={selectedTutorial}
      />
    </>
  );
}
