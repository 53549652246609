import { useContext } from 'react';
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import Media from '../../../../@types/media';
import { ToastContext } from '../../../../contexts/Toast';

type returnType = {
  updateMedia: (
    options?:
      | MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>>
      | undefined
  ) => Promise<
    FetchResult<unknown, Record<string, unknown>, Record<string, unknown>> | { mediaByCaptureModule: Media }
  >;
  loading: boolean;
  data: Media;
};

const GET_MEDIA_BY_CAPTURE_MODULE = gql`
  mutation Mutation($captureModuleId: UUID!) {
    mediaByCaptureModule(capture_module_id: $captureModuleId) {
      media_url
      source_id
      qty_received
      qty_sent
      received_date
      id
      text
      source {
        name
      }
    }
  }
`;

export function useGetMediaByCaptureModule(): returnType {
  const toastContext = useContext(ToastContext);

  const [updateMedia, { loading, data }] = useMutation(GET_MEDIA_BY_CAPTURE_MODULE, {
    update(cache, { data: { mediaByCaptureModule } }) {
      cache.modify({
        fields: {
          captureModules() {
            const newMediaByCapturemodule = cache.writeFragment({
              data: mediaByCaptureModule,
              fragment: gql`
                fragment MediaByCaptureModule on captureModules {
                  media_url
                  source_id
                  qty_received
                  qty_sent
                  received_date
                  id
                  text
                  source {
                    name
                  }
                }
              `,
            });
            return newMediaByCapturemodule;
          },
        },
      });
    },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });

  return { updateMedia, loading, data: data?.mediaByCaptureModule };
}
