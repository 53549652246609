import styled from 'styled-components';

export const Wrapper = styled.div`
  .bad-message {
    color: ${({ theme }) => theme.colors.support.red};
  }

  #fixed-text {
    border-radius: 0.25rem 0 0 0.25rem;
    text-align: right;
  }

  #input-text {
    border-radius: 0 0.25rem 0.25rem 0;

    &:focus {
      box-shadow: none;
  }

  #file-area {
    height: 100px;
    width: 100%;
    display: flex;
    background-color: #${({ theme }) => theme.colors.background};
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
    }

    button {
      margin-top: 24px !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    img {
      width: 90px;
      height: 90px;
    }
  }
`;
