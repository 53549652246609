import { useNavigate } from 'react-router-dom';
import { Container, P14, P16 } from './styles';

interface ICaptureModuleCard {
  id: string;
  name: string;
  questionsToAnswer: number | undefined;
}

export function CaptureModuleCard({ name, questionsToAnswer, id }: ICaptureModuleCard): JSX.Element {
  const navigate = useNavigate();

  const handleCardClick = (): void => {
    if (questionsToAnswer === 0) return;
    navigate(`/curatorship/media/${id}`);
  };

  return (
    <Container onClick={handleCardClick} empty={questionsToAnswer === 0}>
      <P16>{name}</P16>
      <P14>
        {questionsToAnswer} {questionsToAnswer === 1 ? 'mídia na fila' : 'mídias na fila'}
      </P14>
    </Container>
  );
}
