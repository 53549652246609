import styled from 'styled-components';

interface IMediaContainerProps {
  originalSize?: boolean;
}

export const TextContainer = styled.div`
  overflow-y: auto;
  max-height: 420px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
`;

export const MediaContainer = styled.div<IMediaContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  .scroll {
    height: 300px;
    width: 40px;
    overflow-y: scroll;
    display: inline-block;

    .inner {
      height: 300%;
      width: 100%;
      content: '.';
    }
  }

  img,
  video,
  audio {
    width: 90%;
    max-height: ${({ originalSize }) => (originalSize ? 'auto' : '300px')};
  }

  .minifiedImg {
    cursor: zoom-in;
  }
`;
