import { Link, Outlet } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { useState } from 'react';
import { Container, Navbar, NavLink, Wrapper } from './styles';

import IlumeoLogo from '../../assets/images/logo/Ilumeo.svg';

function Onboarding(): JSX.Element {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (): void => {
    if (window.innerWidth < 993) {
      setTimeout(() => {
        setExpanded((prevState) => !prevState);
      }, 150);
    }
  };

  return (
    <Wrapper>
      <Navbar fixed="top" expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand>
            <Link to="/" className="header-title">
              <span>
                Hidden <strong>Insights</strong>
              </span>
              <Image alt="Logo Ilumeo" src={IlumeoLogo} height="22" className="d-inline-block" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" navbarScroll onClick={handleToggle}>
              <NavLink
                to="/onboarding/profile"
                role="button"
                className="nav-link profile"
                onClick={(e) => e.preventDefault()}
              >
                Pessoal
              </NavLink>
              <NavLink to="/onboarding/interesttopics" role="button" className="nav-link interesttopics">
                Temas de Interesse
              </NavLink>
              <NavLink to="/onboarding/favoritebrands" role="button" className="nav-link favoritebrands">
                Marcas Favoritas
              </NavLink>
              <NavLink to="/onboarding/keywords" role="button" className="nav-link keywords">
                Palavras-chave
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </Wrapper>
  );
}

export default Onboarding;
