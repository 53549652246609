import styled from 'styled-components';

export const Container = styled.div<{ empty: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  width: 100%;
  cursor: ${({ empty }) => (empty ? 'not-allowed' : 'pointer')};

  transition: backgroundColor 0.2s;

  background-color: ${({ theme, empty }) => (empty ? theme.colors.neutral[1] : theme.colors.background)};
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  padding: 8px 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.neutral[1]};
  }

  @media (max-width: 1200px) {
    max-width: 420px;
  }

  @media (max-width: 993px) {
    max-width: 95%;
    margin: 0 auto;
  }
`;

export const P16 = styled.p`
  font-size: 16px;
  font-weight: medium;
  margin-bottom: 0.5rem;
`;

export const P14 = styled.p`
  font-size: 14px;
  font-weight: regular;
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral[6]};
`;
