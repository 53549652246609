import { CaptureModuleCard } from '../../components/CaptureModuleCard';
import { useGetCaptureModules } from '../../hooks/graphql/Admin/CaptureModules/useGetCaptureModules';
import { Container, Content, Wrapper, Header } from './styles';
import { H1 } from '../../components/Text';

export default function Curatorship(): JSX.Element {
  const { data: captureModules } = useGetCaptureModules();

  return (
    <>
      <Wrapper>
        <Container>
          <Header>
            <H1>Curadoria</H1>
          </Header>
          <Content>
            {captureModules.length > 0 &&
              captureModules.map((captureModule) => (
                <CaptureModuleCard
                  id={captureModule.id}
                  name={captureModule.name}
                  questionsToAnswer={captureModule.questionsToAnswer}
                  key={captureModule.id}
                />
              ))}
          </Content>
        </Container>
      </Wrapper>
    </>
  );
}
