import styled from 'styled-components';

export const Wrapper = styled.div`
  top: 100px;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;

  img {
    width: 180px;
    height: 180px;
  }

  span {
    font-size: 22px;
    width: 70%;
    text-align: center;
  }
`;
