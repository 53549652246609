import { useGetTutorialCategories } from 'hooks/graphql/Admin/TutorialCategories/useGetTutorialCategories';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { NavLink, Outlet } from 'react-router-dom';
import { H1 } from '../../components/Text';
import { Container, Content, Header, Wrapper } from './styles';

function Tutorials(): JSX.Element {
  const { data: tutorialCategoriesData } = useGetTutorialCategories();

  return (
    <Wrapper>
      <Container>
        <Header>
          <Col lg={12}>
            <H1>Vídeos e tutoriais</H1>
          </Col>
        </Header>
        <Content>
          <Col lg={3}>
            <Nav className="flex-column" defaultActiveKey="profile">
              <Nav.Item>
                <NavLink to="/tutorials/all" className="profile nav-link">
                  Todos
                </NavLink>
              </Nav.Item>
              {tutorialCategoriesData?.map((tutorialCategory) => (
                <Nav.Item key={tutorialCategory.id}>
                  <NavLink to={`/tutorials/${tutorialCategory.id}`} className="categories nav-link">
                    {tutorialCategory.name}
                  </NavLink>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col lg={9}>
            <Outlet />
          </Col>
        </Content>
      </Container>
    </Wrapper>
  );
}

export default Tutorials;
