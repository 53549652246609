import Image from 'react-bootstrap/Image';
import { NotFound } from './styles';

import errorIcon from '../../assets/images/icon/error.svg';

function NotFoundAdminList({ children }: { children: string }): JSX.Element {
  return (
    <NotFound className="notFound-container">
      <Image src={errorIcon} alt="Ícone erro" />
      <span>{children}</span>
    </NotFound>
  );
}

export default NotFoundAdminList;
