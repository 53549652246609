import styled from 'styled-components';

export const CardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[2]};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;

  max-width: 330px;
  padding: 16px;

  cursor: pointer;

  @media (max-width: 990px) {
    margin-top: 1rem;
  }
`;

export const ThumbContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  position: relative;
  height: 350px;

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  .thumb-img {
    max-width: 100%;
    margin-bottom: 0.4rem;
  }
`;

export const Infos = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral[7]};
  margin: 0;
`;

export const Title = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.neutral[8]};
  margin: 8px 0;
`;

export const Description = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral[7]};
  margin: 0;
`;
