import styled from 'styled-components';
import { Toast as ToastBootstrap } from 'react-bootstrap';

export const Toast = styled(ToastBootstrap)`
  width: 500px;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 5px solid ${({ theme }) => theme.colors.support.red};

  #text-container {
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
    justify-content: center;

    & p {
      font-family: 'Raleway', sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin: 0;
      color: ${({ theme }) => theme.colors.neutral[8]};
    }
  }

  button {
    font-family: 'Source Sans Pro', sans-serif;
    color: ${({ theme }) => theme.colors.neutral[6]};
    font-weight: 400;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.neutral[8]};
    }

    &:focus {
      color: ${({ theme }) => theme.colors.neutral[8]};
      box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colorsRGB.neutral[6](0.2)};
    }
  }
`;
