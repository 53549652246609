import NavBarBootstrap from 'react-bootstrap/Navbar';
import ContainerBootstrap from 'react-bootstrap/Container';
import { NavLink as NavLinkReactRouter } from 'react-router-dom';
import styled from 'styled-components';

import feedIcon from '../../assets/images/icon/nav/feed.svg';
import analysisIcon from '../../assets/images/icon/nav/analysis.svg';
import brandsIcon from '../../assets/images/icon/nav/brands.svg';
import favoritesIcon from '../../assets/images/icon/nav/favorites.svg';
import tutorialsIcon from '../../assets/images/icon/nav/tutorials.svg';
import curatorshipIcon from '../../assets/images/icon/nav/curatorship.svg';
import adminIcon from '../../assets/images/icon/nav/admin.svg';

export const Wrapper = styled.div`
  padding-top: 60px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.neutral[1]};
`;

export const Container = styled(ContainerBootstrap)`
  padding: 0;
  margin: auto;
`;

export const Navbar = styled(NavBarBootstrap)`
  min-height: 56px;
  width: 100%;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[1]};

  @media (min-width: 993px) {
    .navbar-nav {
      margin-left: 24px;
    }
  }

  @media (max-width: 993px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  .header-title,
  .header-title:hover,
  .header-title:active,
  .header-title:visited {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    min-height: 48px;
    font-family: 'Raleway', sans-serif;
    color: ${({ theme }) => theme.colors.neutral[8]};
    text-decoration: none;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      padding-right: 8px;
      margin-right: 8px;
      border-right: 1px solid ${({ theme }) => theme.colors.neutral[1]};
      strong {
        font-weight: 600;
      }
    }
  }

  .profile-container {
    display: flex;
    flex-direction: row;

    @media (max-width: 993px) {
      padding: 32px 0;
    }
  }
`;

export const NavLink = styled(NavLinkReactRouter)`
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
  color: ${({ theme }) => theme.colors.neutral[9]} !important;
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  & + & {
    margin-left: 16px;

    @media (max-width: 992px) {
      margin-left: 0;
      padding-left: 24px;
    }
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.neutral[1]};
    font-weight: 600;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;

    &::before {
      margin-right: 8px;
      position: relative;
      top: 2px;
      @media (max-width: 992px) {
        padding-left: 24px;
      }
    }

    &.feed::before {
      content: url(${feedIcon});
    }

    &.analysis::before {
      content: url(${analysisIcon});
    }

    &.brands::before {
      content: url(${brandsIcon});
    }

    &.favorites::before {
      content: url(${favoritesIcon});
    }

    &.tutorials::before {
      content: url(${tutorialsIcon});
    }

    &.curatorship::before {
      content: url(${curatorshipIcon});
    }

    &.admin::before {
      content: url(${adminIcon});
    }
  }
`;

export const NavLinkProfile = styled(NavLinkReactRouter)`
  min-height: 58px;

  &.notifications {
    display: flex;
    align-items: center;
    margin-right: 32px;
    margin-left: 32px;
    padding-left: 8px;
    padding-right: 8px;
  }

  &.profile,
  &.profile:hover,
  &.profile:active,
  &.profile:visited {
    font-family: 'Source Sans Pro', sans-serif;
    color: ${({ theme }) => theme.colors.neutral[8]};
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 8px;
    padding-right: 16px;

    img {
      margin-right: 8px;
    }
  }

  &.notifications.active,
  &.profile.active {
    border-bottom: 2px solid ${({ theme }) => theme.colors.support.blue};

    @media (max-width: 992px) {
      border-bottom: 0;
    }
  }
`;

export const NavButton = styled.button`
  min-height: 58px;
  border: none;
  background-color: transparent;
`;
