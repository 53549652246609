import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ToastContext } from 'contexts/Toast';

const GET_TAGS_SUGGESTION = gql`
  query TagsList {
    tagsList {
      term
      type
    }
  }
`;

export function useGetTagsSuggestions(): {
  data: { term: string; type: 'CONCRETE' | 'INTENT' }[];
  loading: boolean;
} {
  const toastContext = useContext(ToastContext);

  const { data, loading } = useQuery(GET_TAGS_SUGGESTION, {
    onError: () => {
      toastContext.addToast({
        title: 'Erro',
        message: 'Ocorreu um erro ao carregar as sugestões de tags',
        type: 'error',
      });
    },
  });

  return { data: data?.tagsList, loading };
}
