/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetMediaToClone } from 'hooks/graphql/Curatorship/useGetMediaToClone';
import { ButtonAttentionSmall, ButtonSecondarySmall } from '../../../components/Button';
import { Input } from '../../../components/FormComponents/Input';
import { Relevance } from '../../../components/FormComponents/Relevance';
import { Select } from '../../../components/FormComponents/Select';
import { H1 } from '../../../components/Text';
import { useGetMediaByCaptureModule } from '../../../hooks/graphql/Admin/Media/useGetMediaByCaptureModule';
import {
  Container,
  Content,
  Header,
  Wrapper,
  LeftContainer,
  FormContainer,
  FormBlock,
  Divisory,
  ButtonContainer,
  MidiaContainer,
  ButtonPrimarySmallCustom,
  CloneMediaContainer,
  DisabledButton,
} from './styles';

import { ToastContext } from '../../../contexts/Toast';
import { Tags } from '../../../components/FormComponents/Tags';

import { useGetInterestTopics } from '../../../hooks/graphql/Admin/InterestTopics/useGetInterestTopics';
import { ShowMedia } from '../../../components/FormComponents/ShowMedia';
import { IrregularMediaModal } from '../../../components/Curatorship/IrregularMediaModal';
import { useSaveCuratorShip } from '../../../hooks/graphql/Admin/Media/useSaveCuratorship';
import { curatorshipYupValidator } from '../../../utils/validators/curatorshipYupValidator';
import { CloneMediaCard } from '../../../components/Curatorship/CloneMediaCard';
import DwMedia from '../../../@types/dwMedia';
import { TagsMediaClone } from '../../../components/TagsMediaClone';
import { useGetCaptureModules } from '../../../hooks/graphql/Admin/CaptureModules/useGetCaptureModules';

interface ITag {
  id: string;
  text: string;
}

interface IFormData {
  mediaId: string;
  subject: string;
  interest_topic_id: string;
  intentionTags: ITag[];
  concreteTags: ITag[];
  concreteTagsValences: number[];
  receivedDate: string;
  qtyReceived: number;
  qtySent: number;
  sourceName: string;
  relevance: number;
}

interface ICloneFormData {
  finalDate: string;
  initialDate: string;
  tags: ITag[];
}

interface ISaveCuratorshipVariables {
  mediaId: string;
  mediaMain?: string;
  subject: string;
  interestTopicId: string;
  intentTags: string[];
  concreteTags: string[];
  concreteTagsValences: number[];
  relevance: number;
}

export function MediaAnalisys(): JSX.Element {
  const [cloneMediaFilters, setCloneMediaFilters] = useState({
    tags: [''],
    initialDate: '',
    finalDate: '',
  });

  useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      // eslint-disable-next-line no-return-assign
      return (e.returnValue =
        'Ao atualizar a página, todo o seu progresso será perdido e esta mídia pode demorar até 6h para ficar disponível novamente para curadoria. Deseja prosseguir?');
    });

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.removeEventListener('beforeunload', () => {});
    };
  }, []);

  const [mediaMain, setMediaMain] = useState('');

  const { updateMedia, loading, data: media } = useGetMediaByCaptureModule();
  const { data: interestedTopics } = useGetInterestTopics();
  const { saveCuratorship } = useSaveCuratorShip();
  const { data: mediaToClone } = useGetMediaToClone(
    cloneMediaFilters.tags,
    cloneMediaFilters.initialDate,
    cloneMediaFilters.finalDate
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const toastContext = useContext(ToastContext);

  const { id } = useParams();
  const methods = useForm<IFormData>({ resolver: yupResolver(curatorshipYupValidator) });

  const cloneMediaMethods = useForm<ICloneFormData>();

  const navigate = useNavigate();

  useEffect(() => {
    cloneMediaMethods.setValue('initialDate', '2021-01-01');
    cloneMediaMethods.setValue('finalDate', new Date().toLocaleDateString('en-CA'));
  }, [cloneMediaMethods]);

  const updateData = useCallback(async (): Promise<void> => {
    try {
      await updateMedia({
        variables: {
          captureModuleId: id,
        },
      });
    } catch (err) {
      navigate('/curatorship');
    }
  }, [id, navigate, updateMedia]);

  useEffect(() => {
    methods.setValue('receivedDate', moment(media?.received_date).format('DD/MM/YYYY'));
    methods.setValue('qtyReceived', media?.qty_received);
    methods.setValue('qtySent', media?.qty_sent);
    methods.setValue('sourceName', media?.source.name);
  }, [media, methods]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      await updateData();
    }
    loadData();
  }, [updateData]);

  const { data: captureModules } = useGetCaptureModules();
  const remainingMediasOnThisCaptureModule = captureModules?.find((cm) => cm.id === id)?.questionsToAnswer;

  console.log(remainingMediasOnThisCaptureModule);

  async function onSubmit(data: IFormData, saveAndContinue: boolean): Promise<void> {
    try {
      const variables: ISaveCuratorshipVariables = {
        mediaId: media.id,
        subject: data.subject,
        interestTopicId: data.interest_topic_id,
        intentTags: data.intentionTags.map((tag) => tag.text.toLowerCase()),
        concreteTags: data.concreteTags.map((tag) => tag.text.toLowerCase()),
        concreteTagsValences: data.concreteTagsValences,
        relevance: Number(data.relevance),
      };

      if (mediaMain) {
        variables.mediaMain = mediaMain;
      }

      await saveCuratorship({
        variables,
      });
    } catch (err) {
      toastContext.addToast({
        title: 'Erro',
        message: 'Ocorreu um erro ao enviar a curadoria',
        type: 'error',
      });
    }
    methods.reset();
    cloneMediaMethods.reset();
    setMediaMain('');
    methods.setValue('intentionTags', []);
    methods.setValue('concreteTags', []);
    methods.setValue('concreteTagsValences', []);

    if (saveAndContinue) {
      if (remainingMediasOnThisCaptureModule === 0) {
        navigate('/curatorship');
      }
      await updateData();
    } else {
      navigate('/curatorship');
    }
  }

  async function onSearchMediaToClone(data: ICloneFormData): Promise<void> {
    if (data.initialDate > data.finalDate) {
      toastContext.addToast({
        title: 'Erro',
        message: 'A data inicial não pode ser maior que a final',
        type: 'error',
      });

      return;
    }

    setCloneMediaFilters({
      finalDate: data.finalDate,
      initialDate: data.initialDate,
      tags: data.tags.map((tag) => tag.text.toLowerCase()),
    });
  }

  const cloneMedia = useCallback(
    (dwMedia: DwMedia) => {
      methods.setValue('subject', dwMedia.subject);
      methods.setValue('interest_topic_id', dwMedia.interest_topic_id);
      methods.setValue('relevance', dwMedia.relevance);
      methods.setValue(
        'intentionTags',
        dwMedia.intent_tags.map((tag) => {
          return {
            id: tag,
            text: tag,
          };
        })
      );
      methods.setValue(
        'concreteTags',
        dwMedia.concrete_tags.map((tag) => {
          return {
            id: tag,
            text: tag,
          };
        })
      );
      methods.setValue('concreteTagsValences', dwMedia.concrete_tags_valences);

      setMediaMain(dwMedia.id);
      document.getElementById('root')?.scrollIntoView({ behavior: 'smooth' });
    },
    [methods]
  );

  return (
    <>
      <Wrapper>
        <Container>
          <Header>
            <H1>Curadoria de mídia</H1>
          </Header>
          <Content>
            <LeftContainer>
              <MidiaContainer>
                {!loading && <ShowMedia media={media?.media_url} text={media?.text} />}
              </MidiaContainer>
              <ButtonContainer>
                <ButtonAttentionSmall onClick={() => setIsModalVisible(true)}>
                  Mídia irregular
                </ButtonAttentionSmall>
              </ButtonContainer>
            </LeftContainer>
            <FormProvider {...methods}>
              <FormContainer>
                <FormBlock>
                  <Input name="subject" label="Assunto da mídia" />
                  <Select
                    name="interest_topic_id"
                    label="Tema da mídia"
                    options={interestedTopics.map((interestedTopic) => {
                      return {
                        value: interestedTopic.id,
                        label: interestedTopic.name,
                      };
                    })}
                  />
                </FormBlock>
                <FormBlock>
                  <Tags
                    name="concreteTags"
                    label="Tags concretas"
                    valenceName="concreteTagsValences"
                    showSuggestions="CONCRETE"
                  />
                  <Tags name="intentionTags" label="Tags de intenção" showSuggestions="INTENT" />
                </FormBlock>
                <FormBlock>
                  <Relevance label="Relevância" name="relevance" />
                </FormBlock>
                <Divisory />
                <FormBlock>
                  <Input label="Fonte da mídia" name="sourceName" readOnly />
                  <Input label="Data do recebimento" name="receivedDate" readOnly />
                </FormBlock>

                <FormBlock>
                  <Input label="Número de recebimentos do respondente" name="qtyReceived" readOnly />
                  <Input label="Número de encaminhamentos do respondente" name="qtySent" readOnly />
                </FormBlock>
                <ButtonContainer margin="1rem 0 1rem 0">
                  <ButtonSecondarySmall onClick={methods.handleSubmit((data) => onSubmit(data, false))}>
                    Enviar e sair
                  </ButtonSecondarySmall>
                  {remainingMediasOnThisCaptureModule !== 0 && (
                    <ButtonSecondarySmall onClick={methods.handleSubmit((data) => onSubmit(data, true))}>
                      Enviar e continuar
                    </ButtonSecondarySmall>
                  )}
                  {remainingMediasOnThisCaptureModule === 0 && (
                    <DisabledButton title="Não existem mais mídias a serem curadas neste módulo de captura">
                      Enviar e continuar
                    </DisabledButton>
                  )}
                </ButtonContainer>
              </FormContainer>
            </FormProvider>
          </Content>
        </Container>

        <Container>
          <Header>
            <H1>Mídias a serem clonadas</H1>
          </Header>
          <Content>
            <FormProvider {...cloneMediaMethods}>
              <FormContainer>
                <FormBlock>
                  <TagsMediaClone name="tags" label="Buscar por termos" />
                  <Input label="Data inicial" name="initialDate" innerLabel type="date" />
                  <Input label="Data final" name="finalDate" innerLabel type="date" />
                  <ButtonPrimarySmallCustom onClick={cloneMediaMethods.handleSubmit(onSearchMediaToClone)}>
                    BUSCAR
                  </ButtonPrimarySmallCustom>
                </FormBlock>
              </FormContainer>
            </FormProvider>
          </Content>
          <CloneMediaContainer>
            {mediaToClone.length > 0 &&
              mediaToClone?.map((dwMedia) => (
                <CloneMediaCard key={dwMedia.id} dwMedia={dwMedia} cloneMediaFunc={cloneMedia} />
              ))}
          </CloneMediaContainer>
        </Container>
      </Wrapper>

      <IrregularMediaModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        mediaId={media?.id}
      />
    </>
  );
}
