import styled from 'styled-components';
import InputGroupBootstrap from 'react-bootstrap/InputGroup';

import clearField from '../../assets/images/icon/clear-field.svg';
import searchIcon from '../../assets/images/icon/search.svg';

export const InputGroup = styled(InputGroupBootstrap)`
  margin-bottom: 16px;

  input {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    border-color: rgba(0, 0, 0, 0.125);
    margin-right: 1px;
    padding-left: 0;

    &:focus,
    &:active,
    &:hover {
      border-color: rgba(0, 0, 0, 0.125);
      box-shadow: 0 0 0 0;
    }
  }

  #search-icon,
  #clear-field {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.125);
    background-repeat: no-repeat;
    background-position: center;
    height: 48px;
    width: 48px;
  }

  #clear-field {
    background-image: url(${clearField});
    border-radius: 0 ${({ theme }) => theme.borderRadius.medium}px
      ${({ theme }) => theme.borderRadius.medium}px 0;
    border-left: 0;
  }

  #search-icon {
    background-image: url(${searchIcon});
    border-radius: ${({ theme }) => theme.borderRadius.medium}px 0 0
      ${({ theme }) => theme.borderRadius.medium}px;
    border-width: 1px;
    border-style: solid;
    margin-right: 1px;
    border-right: 0;
  }

  #search-icon:focus,
  input:focus,
  #clear-field:focus {
    box-shadow: 0 0 0 0;
    border-color: rgba(0, 0, 0, 0.125);
  }

  /* display: block;
  width: 100%;
  height: 48px;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background-color: ${({ theme }) => theme.colors.neutral[1]};

  &::before {
    content: '';
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    background-position: center;
    height: 48px;
    width: 48px;
  } */
`;
