/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormLabel } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Container, Content } from './styles';
import starIcon from '../../../assets/images/icon/star.svg';
import starFilledIcon from '../../../assets/images/icon/filled-star.svg';

interface IRelevance {
  label: string;
  name: string;
}

export function Relevance({ label, name }: IRelevance): JSX.Element {
  const {
    control,
    watch,
    formState: { isSubmitSuccessful },
  } = useFormContext();
  const watchRelevance = watch('relevance');
  const [stars, setStars] = useState([
    {
      id: 5,
      active: false,
    },
    {
      id: 4,
      active: false,
    },
    {
      id: 3,
      active: false,
    },
    {
      id: 2,
      active: false,
    },
    {
      id: 1,
      active: false,
    },
  ]);
  const [starCount, setStarCount] = useState(0);

  useEffect(() => {
    setStarCount(watchRelevance);

    const starsCopy = [...stars];

    starsCopy.forEach((star) => {
      if (star.id <= watchRelevance) {
        star.active = true;
      } else {
        star.active = false;
      }
    });

    setStars(starsCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchRelevance]);

  useEffect(() => {
    setStarCount(0);
    setStars([
      {
        id: 5,
        active: false,
      },
      {
        id: 4,
        active: false,
      },
      {
        id: 3,
        active: false,
      },
      {
        id: 2,
        active: false,
      },
      {
        id: 1,
        active: false,
      },
    ]);
  }, [isSubmitSuccessful]);

  const handleClick = (id: number): void => {
    const starsCopy = [...stars];

    starsCopy.forEach((star) => {
      if (star.id <= id) {
        star.active = true;
      } else {
        star.active = false;
      }
    });

    setStars(starsCopy);
    setStarCount(id);
  };

  return (
    <Container className="input">
      <FormLabel>{label}</FormLabel>
      <Content>
        <Controller
          defaultValue={starCount}
          name={name}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <>
              {stars.map((star) => (
                <img
                  key={star.id}
                  src={star.active ? starFilledIcon : starIcon}
                  alt="Relevance"
                  onClick={() => {
                    handleClick(star.id);
                    field.onChange(star.id);
                  }}
                />
              ))}
            </>
          )}
        />
      </Content>
    </Container>
  );
}
