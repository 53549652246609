import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import GlobalStyles from '../../assets/styles/global';
import defaultTheme from '../../assets/styles/themes/default';
import Router from '../../Routes';
import client from '../../services/ApolloClient';
import { UserContextProvider } from '../../contexts/User';
import { ToastContextProvider } from '../../contexts/Toast';

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <UserContextProvider>
        <ThemeProvider theme={defaultTheme}>
          <ApolloProvider client={client}>
            <ToastContextProvider>
              <GlobalStyles />
              <Router />
            </ToastContextProvider>
          </ApolloProvider>
        </ThemeProvider>
      </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;
