import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

type returnType = {
  logout: (
    options?:
      | MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>>
      | undefined
  ) => Promise<FetchResult<unknown, Record<string, unknown>, Record<string, unknown>>>;
};

const LOGOUT = gql`
  mutation Mutation {
    logout
  }
`;

export function useLogout(): returnType {
  const [logout] = useMutation(LOGOUT);

  return { logout };
}
