import { useNavigate } from 'react-router-dom';
import TutorialCategory from '../../../@types/tutorialCategory';
import { P16 } from '../../Text';
import { Container, Placeholder } from './styles';

function TutorialCategoryCard({ id, name }: TutorialCategory): JSX.Element {
  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate(`./edit/${id}`)}>
      <P16 className="name">{name}</P16>
    </Container>
  );
}

export default TutorialCategoryCard;

export function TutorialCategoryCardPlaceholder(): JSX.Element {
  return (
    <Placeholder animation="glow">
      <Container>
        <Placeholder className="w-75" />
      </Container>
    </Placeholder>
  );
}
