import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ToastContext } from '../../../../contexts/Toast';
import CaptureModule from '../../../../@types/captureModule';

const GET_CAPTURE_MODULES = gql`
  query Query {
    captureModules {
      id
      name
      start_date
      end_date
      url
      questionsToAnswer
    }
  }
`;

export function useGetCaptureModules(): { data: CaptureModule[]; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_CAPTURE_MODULES, {
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: [], loading: false };
  }
  return { data: data ? data.captureModules : ([] as CaptureModule[]), loading };
}

const GET_CAPTURE_MODULE = gql`
  query Query($captureModuleId: UUID!) {
    captureModule(id: $captureModuleId) {
      id
      name
      url
      start_date
      end_date
    }
  }
`;

export function useGetCaptureModule(id: string | undefined): { data: CaptureModule; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_CAPTURE_MODULE, {
    variables: { captureModuleId: id },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: {} as CaptureModule, loading: false };
  }
  return { data: data ? data.captureModule : ({} as CaptureModule), loading };
}
