import styled from 'styled-components';

export const Container = styled.div`
  max-width: 280px;
  width: 100%;
  height: 450px;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-right: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.neutral[3]};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background: ${({ theme }) => theme.colors.background};
`;

export const TextContainer = styled.div`
  > p {
    margin-bottom: 1rem;
  }
`;

export const ReceivedContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RepetitionText = styled.p`
  color: ${({ theme }) => theme.colors.neutral[6]};
  margin: 0;
  padding: 0;
  margin-left: 8px;
`;

export const CloneButton = styled.div`
  background: ${({ theme }) => theme.colorsRGB.primary.main(0.08)};
  max-width: 180px;
  width: 100%;
  padding: 4px 8px;

  margin-top: 2px;
  cursor: pointer;

  transition: background 0.2s ease-in-out;

  img {
    margin-right: 4px;
    width: 24px;
    height: 24px;
  }

  span {
    font-size: 14px;
    font-weight: 700;

    color: ${({ theme }) => theme.colors.primary.light};
  }

  &:hover {
    background: ${({ theme }) => theme.colorsRGB.primary.main(0.16)};
  }
`;
