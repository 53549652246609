import styled from 'styled-components';
import Pagination from 'react-bootstrap/Pagination';

export const PaginationBs = styled(Pagination)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & .page-link {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary.main};
    background-color: ${({ theme }) => theme.colors.background};

    &:hover {
      background-color: ${({ theme }) => theme.colors.neutral[1]};
    }

    &:focus {
      box-shadow: 0 0 0 0;
    }
  }

  & .page-item.active .page-link {
    border: 1px solid ${({ theme }) => theme.colors.secondary.main};
    background-color: ${({ theme }) => theme.colors.secondary.main};
    color: ${({ theme }) => theme.colors.background};
  }
`;
