import { useContext } from 'react';
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { ToastContext } from '../../../../contexts/Toast';

type returnType = {
  saveCuratorship: (
    options?:
      | MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>>
      | undefined
  ) => Promise<FetchResult<unknown, Record<string, unknown>, Record<string, unknown>>>;
  loading: boolean;
};

const SAVE_CURATORSHIP = gql`
  mutation SaveCuratorship(
    $mediaId: UUID!
    $mediaMain: UUID
    $subject: String
    $interestTopicId: UUID
    $intentTags: [String]
    $concreteTags: [String]
    $concreteTagsValences: [Int]
    $relevance: Int
  ) {
    saveCuratorship(
      media_id: $mediaId
      media_main: $mediaMain
      subject: $subject
      interest_topic_id: $interestTopicId
      intent_tags: $intentTags
      concrete_tags: $concreteTags
      concrete_tags_valences: $concreteTagsValences
      relevance: $relevance
    ) {
      id
      media_id
    }
  }
`;

export function useSaveCuratorShip(): returnType {
  const toastContext = useContext(ToastContext);

  const [saveCuratorship, { loading }] = useMutation(SAVE_CURATORSHIP, {
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
    onCompleted: () => {
      toastContext.addToast({
        title: 'Sucesso',
        message: 'Dados da curadoria enviados com sucesso',
        type: 'success',
      });
    },
  });
  return { saveCuratorship, loading };
}
