import commonStyles from './common';

export default {
  ...commonStyles,
  colors: {
    background: '#FFFFFF',
    primary: {
      light: '#FF8C00',
      main: '#EB5F10',
    },
    secondary: {
      light: '#1D2861',
      main: '#373E98',
    },
    neutral: {
      1: '#EEF2F6',
      2: '#E7ECF2',
      3: '#D5DDE5',
      4: '#BCC7D4',
      5: '#ADB9C7',
      6: '#8F9CA9',
      7: '#596E82',
      8: '#2C4058',
      9: '#1F2934',
    },
    support: {
      blue: '#2F80ED',
      green: '#27AE60',
      yellow: '#FFC50D',
      red: '#F05359',
      irisBlue: '#00BCD4',
      purple: '#6C7AEF',
      lightGreen: '#A9DFBF',
      LightestGreen: '#D4EFDF',
      lightBlue: '#ACCCF8',
      LightestBlue: '#D5E6FB',
    },
  },
  colorsRGB: {
    background: (opacity = 1): string => `rgba(255, 255, 255, ${opacity})`,
    primary: {
      light: (opacity = 1): string => `rgba(255, 140, 0, ${opacity})`,
      main: (opacity = 1): string => `rgba(235, 95, 16, ${opacity})`,
    },
    secondary: {
      light: (opacity = 1): string => `rgba(29, 40, 97, ${opacity})`,
      main: (opacity = 1): string => `rgba(55, 62, 152, ${opacity})`,
    },
    neutral: {
      1: (opacity = 1): string => `rgba(238, 242, 246, ${opacity})`,
      2: (opacity = 1): string => `rgba(231, 236, 242, ${opacity})`,
      3: (opacity = 1): string => `rgba(213, 221, 229, ${opacity})`,
      4: (opacity = 1): string => `rgba(188, 199, 212, ${opacity})`,
      5: (opacity = 1): string => `rgba(173, 185, 199, ${opacity})`,
      6: (opacity = 1): string => `rgba(143, 156, 169, ${opacity})`,
      7: (opacity = 1): string => `rgba(89, 110, 130, ${opacity})`,
      8: (opacity = 1): string => `rgba(44, 64, 88, ${opacity})`,
      9: (opacity = 1): string => `rgba(31, 41, 52, ${opacity})`,
    },
    support: {
      blue: (opacity = 1): string => `rgba(47, 128, 237, ${opacity})`,
      green: (opacity = 1): string => `rgba(39, 174, 96, ${opacity})`,
      yellow: (opacity = 1): string => `rgba(255, 197, 13, ${opacity})`,
      red: (opacity = 1): string => `rgba(240, 83, 89, ${opacity})`,
      irisBlue: (opacity = 1): string => `rgba(0, 188, 212, ${opacity})`,
      purple: (opacity = 1): string => `rgba(108, 122, 239, ${opacity})`,
      lightGreen: (opacity = 1): string => `rgba(169, 223, 191, ${opacity})`,
      LightestGreen: (opacity = 1): string => `rgba(212, 239, 223, ${opacity})`,
      lightBlue: (opacity = 1): string => `rgba(172, 204, 248, ${opacity})`,
      LightestBlue: (opacity = 1): string => `rgba(213, 230, 251, ${opacity})`,
    },
  },
  boxShadow: {
    1: '0px 44px 60px rgba(55,  62,  152, 0.16)',
    2: '0px 16px 60px rgba(82,  55,  192, 0.08)',
    3: '0px  8px 24px rgba(124, 153, 176, 0.32)',
    4: '0px 16px 40px rgba(55,  62,  152, 0.16)',
  },
  gradient: {
    main: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
  },
  gradientRGBA: {
    main: (opacityOne = 1, opacityTwo = 1): string =>
      `linear-gradient(135deg, rgba(252, 207, 49, ${opacityOne}) 0%, rgba(245, 85, 85, ${opacityTwo}) 100%)`,
  },
};
