import Image from 'react-bootstrap/Image';
import { Wrapper } from './styles';

import notebookIcon from '../../../../assets/images/icon/notebook.svg';
import { BoldText } from '../../../../components/Text';

function NoOperationTutorials(): JSX.Element {
  return (
    <Wrapper>
      <Image src={notebookIcon} alt="Ícone notebook" />
      <span>
        Clique em <BoldText>NOVO</BoldText> para criar um novo tutorial ou clique sobre um dos tutoriais da
        lista para editá-lo
      </span>
    </Wrapper>
  );
}

export default NoOperationTutorials;
