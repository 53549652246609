import ContainerBs from 'react-bootstrap/Container';
import styled from 'styled-components';

export const Container = styled(ContainerBs)`
  padding-bottom: 1.5rem;
  padding-left: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-end;

  img {
    user-select: none;
    cursor: pointer;
    &:hover ~ img {
      filter: opacity(1);
    }

    &:hover {
      filter: opacity(1.1);
    }

    & + img {
      margin-right: 4px;
    }
  }
`;
