import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import ContainerBs from 'react-bootstrap/Container';

import bgProfile01 from '../../assets/images/background/bg-profile-1.svg';
import bgProfile02 from '../../assets/images/background/bg-profile-2.svg';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  background-image: url(${bgProfile01}), url(${bgProfile02});
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: left -1% bottom 5%, right 2% top 10%;
  min-height: calc(100vh - 60px);
  padding-top: 1rem;
`;

export const Container = styled(ContainerBs)``;

export const Content = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;

  margin-top: 2rem;
`;

export const Header = styled(Row)`
  margin-bottom: 3rem;

  & .col-lg-12 {
    padding-left: 0;
  }
`;
