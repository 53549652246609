import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

export const ButtonPrimaryHigher = styled(Button)`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.primary.main};
  border: none;
  color: #fff;
  line-height: 54px;
  width: 100%;
  padding: 0 16px;
  border-radius: 0.5rem;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.primary.main};
    border: none;
    color: #fff;
    filter: brightness(0.9);
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.primary.main};
    border: none;
    color: #fff;
    filter: brightness(1.2);
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colorsRGB.primary.main(0.5)};
  }
`;

export const ButtonSecondaryHigher = styled(Button)`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.secondary.main};
  border: none;
  color: #fff;
  line-height: 54px;
  width: 100%;
  padding: 0 16px;
  border-radius: 0.5rem;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.secondary.main};
    border: none;
    color: #fff;
    filter: brightness(0.9);
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.secondary.main};
    border: none;
    color: #fff;
    filter: brightness(1.2);
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colorsRGB.secondary.main(0.5)};
  }
`;

export const ButtonSecondaryOutlineHigher = styled(Button)`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.secondary.main};
  color: ${({ theme }) => theme.colors.secondary.main};
  line-height: 54px;
  width: 100%;
  padding: 0 16px;
  border-radius: 0.5rem;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.secondary.light};
    border: 1px solid ${({ theme }) => theme.colors.secondary.light};
    background-color: ${({ theme }) => theme.colors.background};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.secondary.light};
    border: 1px solid ${({ theme }) => theme.colors.secondary.light};
    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colorsRGB.secondary.main(0.5)};
  }
`;

export const ButtonSecondarySmall = styled(Button)`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.secondary.main};
  border: none;
  color: #fff;
  line-height: 48px;
  width: 100%;
  padding: 0 16px;
  border-radius: 0.5rem;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.secondary.light};
    border: none;
    color: #fff;
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.secondary.light};
    border: none;
    color: #fff;
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colorsRGB.secondary.main(0.5)};
  }
`;

export const ButtonPrimarySmall = styled(Button)`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.primary.main};
  border: none;
  color: #fff;
  line-height: 48px;
  width: 100%;
  padding: 0 16px;
  border-radius: 0.5rem;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.primary.light};
    border: none;
    color: #fff;
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.primary.light};
    border: none;
    color: #fff;
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colorsRGB.primary.main(0.5)};
  }
`;

export const ButtonSecondaryOutlineSmallest = styled(Button)`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.secondary.main};
  color: ${({ theme }) => theme.colors.secondary.main};
  line-height: 30px;
  width: 100%;
  padding: 0 16px;
  border-radius: 0.5rem;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.secondary.light};
    border: 1px solid ${({ theme }) => theme.colors.secondary.light};
    background-color: ${({ theme }) => theme.colors.background};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.secondary.light};
    border: 1px solid ${({ theme }) => theme.colors.secondary.light};
    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colorsRGB.secondary.main(0.5)};
  }
`;

export const ButtonAttentionSmall = styled(Button)`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.support.red};
  border: none;
  color: #fff;
  line-height: 48px;
  width: 100%;
  padding: 0 16px;
  border-radius: 0.5rem;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colorsRGB.support.red(0.8)};
    border: none;
    color: #fff;
  }

  &:focus {
    background-color: ${({ theme }) => theme.colorsRGB.support.red(0.8)};
    border: none;
    color: #fff;
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colorsRGB.support.red(0.5)};
  }
`;
