/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import { FloatingLabel, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

interface IInputProps {
  name: string;
  label: string;
  readOnly?: boolean;
  innerLabel?: boolean;
  type?: string;
}

export function Input({
  name,
  label,
  readOnly = false,
  innerLabel,
  type = 'text',
}: IInputProps): JSX.Element {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {innerLabel ? (
        <Form.Group controlId={name} className="mb-3">
          <FloatingLabel label={label}>
            <Form.Control
              type={type}
              isInvalid={!!errors[name]}
              readOnly={readOnly}
              max="2100-01-01"
              {...register(name)}
              placeholder={label}
            />
          </FloatingLabel>
        </Form.Group>
      ) : (
        <Form.Group controlId={name} className="mb-3">
          <Form.Label>{label}</Form.Label>
          <Form.Control type={type} isInvalid={!!errors[name]} readOnly={readOnly} {...register(name)} />
        </Form.Group>
      )}
    </>
  );
}
