import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import InterestTopic from '../../../../@types/interestTopic';
import { ToastContext } from '../../../../contexts/Toast';

const GET_INTEREST_TOPICS = gql`
  query Query {
    interestTopics {
      id
      name
      fg_active
    }
  }
`;

export function useGetInterestTopics(): { data: InterestTopic[]; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_INTEREST_TOPICS, {
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: [], loading: false };
  }
  return { data: data ? data.interestTopics : ([] as InterestTopic[]), loading };
}

const GET_INTEREST_TOPIC = gql`
  query Query($interestTopicId: UUID!) {
    interestTopic(id: $interestTopicId) {
      id
      name
      fg_active
    }
  }
`;

export function useGetInterestTopic(id: string | undefined): { data: InterestTopic; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_INTEREST_TOPIC, {
    variables: { interestTopicId: id },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: {} as InterestTopic, loading: false };
  }
  return { data: data ? data.interestTopic : ({} as InterestTopic), loading };
}
