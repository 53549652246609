import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import Justification from '../../../../@types/justification';
import { ToastContext } from '../../../../contexts/Toast';

const GET_JUSTIFICATIONS = gql`
  query Query {
    justifications {
      id
      name
    }
  }
`;

export function useGetJustifications(): { data: Justification[]; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_JUSTIFICATIONS, {
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: [], loading: false };
  }
  return { data: data ? data.justifications : ([] as Justification[]), loading };
}

const GET_JUSTIFICATION = gql`
  query Query($justificationId: UUID!) {
    interestTopic(id: $justificationId) {
      id
      name
    }
  }
`;

export function useGetJustification(id: string | undefined): { data: Justification; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_JUSTIFICATION, {
    variables: { interestTopicId: id },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: {} as Justification, loading: false };
  }
  return { data: data ? data.interestTopic : ({} as Justification), loading };
}
