import styled from 'styled-components';

export const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  padding: 16px;
  text-align: center;

  span {
    font-size: 16px;
    margin-top: 24px;
  }
`;
