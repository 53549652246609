import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { ToastContext } from 'contexts/Toast';
import { useContext } from 'react';

type returnType = {
  likeMedia: (
    options?:
      | MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>>
      | undefined
  ) => Promise<FetchResult<unknown, Record<string, unknown>, Record<string, unknown>>>;
  data: boolean;
  loading: boolean;
};

const LIKE_MEDIA = gql`
  mutation Mutation($mediaId: UUID, $operation: LikeOperation) {
    likeMedia(mediaId: $mediaId, operation: $operation) {
      id
      operation
    }
  }
`;

export function useLikeMedia(): returnType {
  const toastContext = useContext(ToastContext);

  const [likeMedia, { data, loading }] = useMutation(LIKE_MEDIA, {
    onError: () => {
      toastContext.addToast({
        type: 'error',
        title: 'Erro',
        message: 'Algo deu errado ao salvar sua operaçao. Por favor, tente novamente',
      });
    },
  });

  return { likeMedia, data, loading };
}
