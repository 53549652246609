import styled from 'styled-components';
import PlaceholderBootstrap from 'react-bootstrap/Placeholder';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  display: flex;
  flex-direction: row;
  padding: 8px 16px;

  & + & {
    margin-top: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[1]};
  }
`;

export const Placeholder = styled(PlaceholderBootstrap)`
  & + & {
    display: block;
    margin-top: 8px;
  }
`;
