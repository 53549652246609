import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { H1, H4, P16 } from '../../../components/Text';
import { Col, ColContainer, Header, HR, Wrapper } from './styles';
import { ButtonSecondarySmall } from '../../../components/Button';
import NotFoundAdminList from '../../../components/NotFoundAdminList';
import SearchField from '../../../components/SearchField';
import { includesStringsNoAccent } from '../../../utils/comparators/stringNoAccent';
import Pagination from '../../../components/Pagination';
import { quantityItensPerPageDefault } from '../../../utils/constants';
import paginate from '../../../utils/paginate';
import TutorialCategoryCard, {
  TutorialCategoryCardPlaceholder,
} from '../../../components/Admin/TutorialCategoryCard';
import { useGetTutorials } from '../../../hooks/graphql/Admin/Tutorials/useGetTutorials';

function Tutorials(): JSX.Element {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const { data, loading } = useGetTutorials();

  const handleSearchQueryChange = (text: string): void => {
    setPage(1);
    setSearchQuery(text);
  };

  const filteredData = data.filter((tutorial) => includesStringsNoAccent(tutorial.title, searchQuery));

  const PaginedData = paginate(filteredData, page, quantityItensPerPageDefault);

  const navigate = useNavigate();

  return (
    <Wrapper>
      <Header>
        <H1>Tutoriais</H1>
        <P16>Gerencie os tutoriais da plataforma</P16>
      </Header>
      <ColContainer>
        <Col>
          <div className="col-header">
            <H4 className="d-block">Listagem de Tutoriais</H4>
            <ButtonSecondarySmall className="m-0" onClick={() => navigate('./create')}>
              NOVO
            </ButtonSecondarySmall>
          </div>
          <HR />
          <SearchField
            placeholder="Buscar tutoriais"
            value={searchQuery}
            changeAction={handleSearchQueryChange}
            clear={() => setSearchQuery('')}
          />
          {loading ? (
            <>
              <TutorialCategoryCardPlaceholder />
              <TutorialCategoryCardPlaceholder />
              <TutorialCategoryCardPlaceholder />
            </>
          ) : (
            <>
              {data &&
                PaginedData.map((tutorialCategory) => (
                  <TutorialCategoryCard
                    key={tutorialCategory.id}
                    id={tutorialCategory.id}
                    name={tutorialCategory.title}
                  />
                ))}

              {(data?.length === 0 || !data || PaginedData.length === 0) && (
                <NotFoundAdminList>Nenhuma tutorial encontrado</NotFoundAdminList>
              )}
              <Pagination
                totalPages={Math.ceil(filteredData.length / quantityItensPerPageDefault)}
                actualPage={page}
                setPage={setPage}
              />
            </>
          )}
        </Col>
        <Col>
          <Outlet />
        </Col>
      </ColContainer>
    </Wrapper>
  );
}

export default Tutorials;
