import { string, object, array } from 'yup';

export const curatorshipYupValidator = object().shape({
  subject: string().trim().required('Assunto da mídia obrigatório'),

  interest_topic_id: string().trim().required('Tema da mídia obrigatório'),

  concreteTags: array().min(1, 'É necessário ao menos uma tag').required('Tags concretas obrigatórias'),

  intentionTags: array().min(1, 'É necessário ao menos uma tag').required('Tags de intenção obrigatórias'),

  relevance: string().trim().required('Relevância obrigatória'),
});
