type UserRole = 'CLIENT' | 'CLIENT_ADMIN' | 'ADMIN' | 'CURATOR';

export const getUserMainPage = (role: UserRole | undefined): string => {
  switch (role) {
    case 'CLIENT':
      return '/feed';
    case 'CLIENT_ADMIN':
      return '/feed';
    case 'ADMIN':
      return '/admin';
    case 'CURATOR':
      return '/curatorship';
    default:
      return 'feed';
  }
};
