/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

interface ISelectOptions {
  value: string;
  label: string;
}

interface IInputProps {
  name: string;
  label: string;
  options: ISelectOptions[];
}

export function Select({ name, label, options }: IInputProps): JSX.Element {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group className="mb-3" controlId={name}>
      <Form.Label>{label}</Form.Label>

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, name } }) => (
          <Form.Select {...register(name)} onChange={(value) => onChange(value)} isInvalid={!!errors[name]}>
            <option value="" label="Selecione uma opção" />
            {options.map((option) => (
              <option key={option.value} value={option.value} label={option.label} />
            ))}
          </Form.Select>
        )}
      />
    </Form.Group>
  );
}
