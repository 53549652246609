import { createContext, useMemo, useState } from 'react';
import User from '../@types/user';

type context = {
  user: User | undefined;
  setUser: (user: User) => void | undefined;
};

export const defaultValue: context = {
  user: {} as User,
  setUser: undefined as unknown as (user: User) => void | undefined,
};

export const UserContext = createContext<context>(defaultValue);

export function UserContextProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [userState, setUserState] = useState<User | undefined>(undefined);

  const user = useMemo(() => {
    if (!userState) {
      const u = localStorage.getItem('@hiddeninsights/user');
      if (u) {
        setUserState(JSON.parse(u));
      }
    }
    return userState;
  }, [userState]);

  const setUser = (param: User): void => {
    setUserState(param);
    localStorage.setItem('@hiddeninsights/user', JSON.stringify(param));
  };

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
}
