import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import ContainerBs from 'react-bootstrap/Container';
import { ButtonPrimarySmall } from '../../../components/Button';

import bgProfile01 from '../../../assets/images/background/bg-profile-1.svg';
import bgProfile02 from '../../../assets/images/background/bg-profile-2.svg';

interface IButtonContainerProps {
  margin?: string;
}

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  background-image: url(${bgProfile01}), url(${bgProfile02});
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: left -1% bottom 5%, right 2% top 10%;
  min-height: calc(100vh - 60px);
  padding-top: 1rem;
`;

export const Container = styled(ContainerBs)`
  background-color: ${({ theme }) => theme.colors.background};
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

export const Header = styled(Row)`
  margin-bottom: 3rem;

  & .col-lg-12 {
    padding-left: 0;
  }
`;

export const LeftContainer = styled.div`
  max-width: 304px;
  width: 100%;
  height: 540px;
  padding: 1rem;

  margin-right: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.neutral[3]};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background: ${({ theme }) => theme.colors.background};

  @media (max-width: 1200px) {
    margin-bottom: 2rem;
  }
`;

export const MidiaContainer = styled.div`
  height: 450px;
`;

export const FormContainer = styled.form`
  flex: 1;
`;

export const FormBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  width: 100%;
  gap: 1rem;

  input,
  select {
    width: 100%;
    height: 48px;
    border-radius: 8px;
  }

  > div {
    width: 100%;
    label {
      color: ${({ theme }) => theme.colors.neutral[7]};
    }
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export const Divisory = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral[4]};
  margin: 1rem 0;
`;

export const ButtonContainer = styled.div<IButtonContainerProps>`
  display: flex;
  justify-content: center;

  ${({ margin }) => margin && `margin: ${margin};`}

  > button {
    max-width: 240px;
    & + button {
      margin-left: 1rem;
    }
  }
`;

export const ButtonPrimarySmallCustom = styled(ButtonPrimarySmall)`
  max-width: 123px;
  max-height: 58px;
`;

export const CloneMediaContainer = styled.div`
  width: 100%;
  height: 500px;
  padding: 2rem;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;

  margin-top: 0;
  margin-bottom: 2rem;

  border: 1px solid ${({ theme }) => theme.colors.neutral[3]};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background: ${({ theme }) => theme.colors.background};

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
`;

export const DisabledButton = styled.button`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.neutral[5]};
  cursor: not-allowed;
  border: none;
  color: #fff;
  line-height: 48px;
  width: 100%;
  padding: 0 32px;
  border-radius: 0.5rem;
`;
