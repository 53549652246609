import NavBarBootstrap from 'react-bootstrap/Navbar';
import ContainerBootstrap from 'react-bootstrap/Container';
import { NavLink as NavLinkReactRouter } from 'react-router-dom';
import styled from 'styled-components';

import checkDisabled from '../../assets/images/icon/check-disabled.svg';
import checkActive from '../../assets/images/icon/check-active.svg';

import bgOnboarding01 from '../../assets/images/background/bg-onboarding-1.svg';
import bgOnboarding02 from '../../assets/images/background/bg-onboarding-2.svg';

export const Wrapper = styled.div`
  padding-top: 60px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  background: no-repeat left 3% bottom 5% url(${bgOnboarding01}),
    no-repeat right -2% top 30% url(${bgOnboarding02});
`;

export const Container = styled(ContainerBootstrap)`
  padding: 0;
`;

export const Navbar = styled(NavBarBootstrap)`
  width: 100%;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[1]};

  @media (min-width: 993px) {
    .navbar-nav {
      width: 100%;
      margin-left: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  @media (max-width: 993px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  .header-title,
  .header-title:hover,
  .header-title:active,
  .header-title:visited {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    min-height: 48px;
    font-family: 'Raleway', sans-serif;
    color: ${({ theme }) => theme.colors.neutral[8]};
    text-decoration: none;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      padding-right: 8px;
      margin-right: 8px;
      border-right: 1px solid ${({ theme }) => theme.colors.neutral[1]};
      strong {
        font-weight: 600;
      }
    }
  }
`;

export const NavLink = styled(NavLinkReactRouter)`
  height: 100%;
  min-height: 60px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
  color: ${({ theme }) => theme.colors.neutral[9]} !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  & + & {
    margin-left: 24px;

    @media (max-width: 992px) {
      margin-left: 0;
      padding-left: 24px;
    }
  }

  &::before {
    content: url(${checkDisabled});
    margin-right: 8px;
  }

  &.active::before {
    content: url(${checkActive});
    margin-right: 8px;
  }

  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.colors.support.blue};

    @media (max-width: 992px) {
      border-bottom: 0;
    }
  }
`;
