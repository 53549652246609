import { ApolloError, gql, QueryLazyOptions, useLazyQuery } from '@apollo/client';

type returnType = {
  getIsAuthenticated: (options?: QueryLazyOptions<{ captureModuleId: string }> | undefined) => void;
  loading: boolean;
  error: ApolloError | undefined;
  data: {
    code: number;
    message: string;
  };
};

const GET_AUTHENTICATED = gql`
  query IsAuthenticated {
    authenticated {
      code
      message
    }
  }
`;

export function useGetAuthenticated(): returnType {
  const [getIsAuthenticated, { loading, error, data }] = useLazyQuery(GET_AUTHENTICATED, {
    fetchPolicy: 'no-cache',
  });

  return {
    getIsAuthenticated,
    loading,
    error,
    data: data ? data.authenticated : { code: 0, message: '' },
  };
}
