import { Link, Outlet, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { useContext, useEffect, useState } from 'react';
import { getUserMainPage } from 'utils/user';
import { useLogout } from 'hooks/graphql/logout';
import User from '../../@types/user';
import { Container, Navbar, NavButton, NavLink, NavLinkProfile, Wrapper } from './styles';

import IlumeoLogo from '../../assets/images/logo/Ilumeo.svg';
import profileIcon from '../../assets/images/icon/nav/profile.svg';
// import notificationIcon from '../../assets/images/icon/nav/notification.svg';
// import unreadNotificationIcon from '../../assets/images/icon/nav/unread-notification.svg';
import logoutIcon from '../../assets/images/icon/nav/logout.svg';
import { UserContext } from '../../contexts/User';

function Base(): JSX.Element {
  // const [hasNotification] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const userContext = useContext(UserContext);

  const navigate = useNavigate();

  const USER_PERMISSIONS = [] as string[];

  const ADMIN_PERMISSIONS = ['FEED', 'ANALYSIS', 'BRANDS', 'FAVORITES', 'TUTORIALS', 'CURATORSHIP', 'ADMIN'];
  const CLIENT_PERMISSIONS = ['FEED', 'ANALYSIS', 'BRANDS', 'FAVORITES', 'TUTORIALS'];
  const CLIENT_ADMIN_PERMISSIONS = ['FEED', 'ANALYSIS', 'BRANDS', 'FAVORITES', 'TUTORIALS', 'ADMIN'];
  const CURATOR_PERMISSIONS = ['CURATORSHIP'];

  useEffect(() => {
    (async () => {
      if (
        userContext === undefined ||
        userContext.user === undefined ||
        userContext.user.name === undefined ||
        userContext.user.role === undefined
      ) {
        const user = await localStorage.getItem('@hiddeninsights/user');
        if (user === null) {
          navigate('/login');
        } else {
          userContext.setUser(JSON.parse(user));
        }
      }
    })();
  }, [navigate, userContext]);

  if (userContext && userContext.user) {
    if (userContext.user.role === 'ADMIN') {
      USER_PERMISSIONS.push(...ADMIN_PERMISSIONS);
    } else if (userContext.user.role === 'CLIENT') {
      USER_PERMISSIONS.push(...CLIENT_PERMISSIONS);
    } else if (userContext.user.role === 'CLIENT_ADMIN') {
      USER_PERMISSIONS.push(...CLIENT_ADMIN_PERMISSIONS);
    } else if (userContext.user.role === 'CURATOR') {
      USER_PERMISSIONS.push(...CURATOR_PERMISSIONS);
    }
  }

  const { logout } = useLogout();

  const handleLogout = async (): Promise<void> => {
    await logout();
    userContext.setUser({} as User);
    localStorage.removeItem('@hiddeninsights/user');
    navigate('/login');
  };

  const handleToggle = (): void => {
    if (window.innerWidth < 993) {
      setTimeout(() => {
        setExpanded((prevState) => !prevState);
      }, 150);
    }
  };

  return (
    <Wrapper>
      <Navbar fixed="top" expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand>
            <Link to={getUserMainPage(userContext?.user?.role)} className="header-title">
              <span>
                Hidden <strong>Insights</strong>
              </span>
              <Image alt="Logo Ilumeo" src={IlumeoLogo} height="22" className="d-inline-block" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" justify navbarScroll onClick={handleToggle}>
              {USER_PERMISSIONS.includes('FEED') && (
                <NavLink to="/feed" role="button" className="nav-link feed">
                  Feed
                </NavLink>
              )}

              {USER_PERMISSIONS.includes('ANALYSIS') && (
                <NavLink to="/analysis" role="button" className="nav-link analysis">
                  Analysis
                </NavLink>
              )}

              {USER_PERMISSIONS.includes('BRANDS') && (
                <NavLink to="/brands" role="button" className="nav-link brands">
                  Brands
                </NavLink>
              )}

              {USER_PERMISSIONS.includes('FAVORITES') && (
                <NavLink to="/favorites" role="button" className="nav-link favorites">
                  Favorites
                </NavLink>
              )}

              {USER_PERMISSIONS.includes('TUTORIALS') && (
                <NavLink to="/tutorials" role="button" className="nav-link tutorials">
                  Tutorials
                </NavLink>
              )}

              {USER_PERMISSIONS.includes('CURATORSHIP') && (
                <NavLink to="/curatorship" role="button" className="nav-link curatorship">
                  Curatorship
                </NavLink>
              )}

              {USER_PERMISSIONS.includes('ADMIN') && (
                <NavLink to="/admin" role="button" className="nav-link admin">
                  Admin
                </NavLink>
              )}
            </Nav>

            <div className="profile-container">
              {/* <NavLinkProfile to="/notifications" className="notifications unread" onClick={handleToggle}>
                <Image
                  alt="Ícone de Notificação"
                  src={hasNotification ? unreadNotificationIcon : notificationIcon}
                  className="d-inline-block"
                />
              </NavLinkProfile> */}
              <NavLinkProfile to="/profile" className="profile" onClick={handleToggle}>
                <Image alt="Ícone perfil" src={profileIcon} className="d-inline-block" />
                {userContext?.user?.name}
              </NavLinkProfile>
              <NavButton className="profile" onClick={() => handleLogout()}>
                <Image alt="Ícone Sair" src={logoutIcon} />
              </NavButton>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </Wrapper>
  );
}

export default Base;
