import { useContext } from 'react';
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { ToastContext } from '../../../contexts/Toast';
import DwMedia from '../../../@types/dwMedia';

type returnType = {
  updateDwMedia: (
    options?:
      | MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>>
      | undefined
  ) => Promise<FetchResult<unknown, Record<string, unknown>, Record<string, unknown>>>;
  loading: boolean;
};

const UPDATE_DW_MEDIA = gql`
  mutation UpdateDwMedia($dwMediaId: UUID, $dwMedia: DwMediaInput) {
    updateDwMedia(id: $dwMediaId, dwMedia: $dwMedia) {
      id
      media_id
      media_url
      media_text
      media_source
      media_type
      media_qty_received
      media_qty_sent
      media_main
      subject
      interest_topic_id
      intent_tags
      concrete_tags
      concrete_tags_valences
      relevance
    }
  }
`;

export function useUpdateDwMedia(): returnType {
  const toastContext = useContext(ToastContext);

  const [updateDwMedia, { loading }] = useMutation(UPDATE_DW_MEDIA, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          captureModules(existingDwMedias = []) {
            cache.readFragment({
              id: `DwMedia:${data.updateDwMedia.id}`,
              fragment: gql`
                fragment DwMedia on dwMedias {
                  id
                  media_id
                  media_url
                  media_text
                  media_source
                  media_type
                  media_qty_received
                  media_qty_sent
                  media_main
                  subject
                  interest_topic_id
                  intent_tags
                  concrete_tags
                  concrete_tags_valences
                  relevance
                }
              `,
            });
            return existingDwMedias.map((dwMedia: DwMedia) => {
              if (dwMedia.id === data.updateCaptureModule.id) {
                return data.updateCaptureModule;
              }
              return dwMedia;
            });
          },
        },
      });
    },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
    onCompleted: () => {
      toastContext.addToast({
        title: 'Sucesso',
        message: 'Mídia editada com sucesso',
        type: 'success',
      });
    },
  });
  return { updateDwMedia, loading };
}
