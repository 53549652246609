import { createContext, useState } from 'react';
import { v4 } from 'uuid';
import ToastContainer from 'react-bootstrap/ToastContainer';
import LikeToast from 'components/Toast/LikeToast';
import ToastProps from '../@types/toast';
import FeedbackToast from '../components/Toast/FeedbackToast';

type context = {
  addToast: (toast: ToastProps) => void | undefined;
};

const defaultValue: context = {
  addToast: undefined as unknown as (toast: ToastProps) => void | undefined,
};

export const ToastContext = createContext<context>(defaultValue);

export function ToastContextProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
  const [toasts, setToasts] = useState<JSX.Element[]>([]);

  const addToast = ({ type, title, message, autohide, delay, mediaId }: ToastProps): void => {
    if (type === 'like') {
      setToasts((prevToasts) => [...prevToasts, <LikeToast key={v4().toString()} mediaId={mediaId} />]);
    } else {
      setToasts((prevToasts) => [
        ...prevToasts,
        <FeedbackToast
          key={v4().toString()}
          type={type}
          title={title}
          message={message}
          autohide={autohide}
          delay={delay}
        />,
      ]);
    }
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <ToastContainer position="bottom-end" className="p-3 position-fixed" style={{ zIndex: 999 }}>
        {toasts.map((toast) => toast)}
      </ToastContainer>
    </ToastContext.Provider>
  );
}
