import { useContext } from 'react';
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { ToastContext } from '../../../../contexts/Toast';

type returnType = {
  setInnapropriateMedia: (
    options?:
      | MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>>
      | undefined
  ) => Promise<FetchResult<unknown, Record<string, unknown>, Record<string, unknown>>>;
  loading: boolean;
};

const SET_INNAPROPRIATE_MEDIA = gql`
  mutation setInappropriateMedia($mediaId: UUID!, $justificationId: UUID!) {
    setInappropriateMedia(media_id: $mediaId, justification_id: $justificationId) {
      id
    }
  }
`;

export function useSetInnapropriateMedia(): returnType {
  const toastContext = useContext(ToastContext);

  const [setInnapropriateMedia, { loading }] = useMutation(SET_INNAPROPRIATE_MEDIA, {
    update(cache, { data: { saveCuratorShip } }) {
      cache.modify({
        fields: {
          companies(saveCuratorShips = []) {
            const newCompanyRef = cache.writeFragment({
              data: saveCuratorShip,
              fragment: gql`
                fragment innapropriateMedia on Companies {
                  id
                  name
                }
              `,
            });
            return [...saveCuratorShips, newCompanyRef];
          },
        },
      });
    },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
    onCompleted: () => {
      toastContext.addToast({
        title: 'Sucesso',
        message: 'Mídia denunciada com sucesso',
        type: 'success',
      });
    },
  });
  return { setInnapropriateMedia, loading };
}
