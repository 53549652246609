/* eslint-disable react/require-default-props */
import DwMedia from '../../../@types/dwMedia';
import { ShowMedia } from '../../FormComponents/ShowMedia';
import { P16 } from '../../Text';
import { CloneButton, Container, RepetitionText, ReceivedContainer, TextContainer } from './styles';
import RepetitionIcon from '../../../assets/images/icon/repetition.svg';
import QueueAdd from '../../../assets/images/icon/queue-add.svg';
import { useUndoCloning } from '../../../hooks/graphql/Curatorship/useUndoCloning';

interface ICloneMediaCard {
  dwMedia: DwMedia;
  cloneMediaFunc: (dwMedia: DwMedia) => void;
  isCloned?: boolean;
}

export function CloneMediaCard({ dwMedia, cloneMediaFunc, isCloned }: ICloneMediaCard): JSX.Element {
  const { undoCloning } = useUndoCloning();
  return (
    <Container>
      <ShowMedia media={dwMedia.media_url} text={dwMedia.media_text} />

      <TextContainer>
        <P16>{dwMedia.subject}</P16>

        <ReceivedContainer>
          <img src={RepetitionIcon} alt="Icone de recebimentos" />
          <RepetitionText>
            {dwMedia.media_qty_received === 1
              ? `${dwMedia.media_qty_received} recebimento`
              : `${dwMedia.media_qty_received} recebimentos`}
          </RepetitionText>
        </ReceivedContainer>
        <CloneButton
          onClick={() =>
            isCloned ? undoCloning({ variables: { id: dwMedia.id } }) : cloneMediaFunc(dwMedia)
          }
        >
          <img src={QueueAdd} alt="Icone de adicionar a fila" />
          <span>{isCloned ? 'Desfazer Clonagem' : 'Clonar esta mídia'}</span>
        </CloneButton>
      </TextContainer>
    </Container>
  );
}
