/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useGetJustifications } from '../../../hooks/graphql/Admin/Justifications/useGetJustications';
import { ButtonAttentionSmall } from '../../Button';
import { useSetInnapropriateMedia } from '../../../hooks/graphql/Admin/Media/useSetInnapropriateMedia';
import { useSetInnapropriateDwMedia } from '../../../hooks/graphql/Curatorship/useSetInnapropriateDwMedia';

import { Select } from '../../FormComponents/Select';
import { FormContainer } from './styles';

interface IIrregularMediaModal {
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
  mediaId: string;
  isEdit?: boolean;
}

interface IFormData {
  mediaId: string;
  justificationId: string;
}

export function IrregularMediaModal({
  isModalVisible,
  setIsModalVisible,
  mediaId,
  isEdit,
}: IIrregularMediaModal): JSX.Element {
  const methods = useForm<IFormData>();
  const { setInnapropriateMedia } = useSetInnapropriateMedia();

  const { setInnapropriateDwMedia } = useSetInnapropriateDwMedia();

  const { data: justifications } = useGetJustifications();

  async function onSubmit(data: IFormData): Promise<void> {
    Object.assign(data, {
      mediaId,
    });

    if (isEdit) {
      await setInnapropriateDwMedia({
        variables: {
          id: data.mediaId,
          justificationId: data.justificationId,
        },
      });

      setIsModalVisible(false);
      return;
    }

    await setInnapropriateMedia({
      variables: {
        mediaId: data.mediaId,
        justificationId: data.justificationId,
      },
    });

    setIsModalVisible(false);
  }

  return (
    <Modal
      show={isModalVisible}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setIsModalVisible(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Denunciar mídia irregular</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <FormContainer>
            <Select
              name="justificationId"
              label="Justificativa"
              options={justifications.map((justification) => {
                return {
                  label: justification.name,
                  value: justification.id,
                };
              })}
            />
          </FormContainer>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <ButtonAttentionSmall onClick={methods.handleSubmit(onSubmit)}>Denunciar</ButtonAttentionSmall>
      </Modal.Footer>
    </Modal>
  );
}
