import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import ContainerBs from 'react-bootstrap/Container';
import searchIcon from '../../assets/images/icon/search.svg';

export const Container = styled(ContainerBs)`
  padding-bottom: 1.5rem;
  padding-left: 0;
  max-width: 400px;

  @media (max-width: 900px) {
    max-width: 860px;
  }
`;

export const TagsContainer = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 1rem;

  .ReactTags__tags {
    position: relative;
    padding: 0 4px 0 4px;
    border: 1px solid #dddddd;

    min-height: 60px;
    border-radius: 8px;
    gap: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
  }

  .ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
  }

  /* Styles for the input */
  .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
    width: 100%;
    min-width: 148px;

    input {
      margin-left: 40px !important;
      width: 80% !important;

      &:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }

      &::placeholder {
        font-size: 16px;
      }
    }

    &::before {
      content: url(${searchIcon});
      position: absolute;
      margin-left: 12px;
      margin-top: 12px;
      z-index: 5;
    }
  }

  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    border: 1px solid #eee;
    border: none;
    outline-width: 0 !important;

    max-width: 500px;
    width: 100%;

    margin-top: 8px;

    font-size: 14px;
  }

  .ReactTags__editInput {
    border-radius: 1px;
  }

  .ReactTags__editTagInput {
    display: inline-flex;
  }

  .ReactTags__selected {
    display: flex;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4);
    }
    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }
  }
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    padding: 2px 4px 2px 8px;
    margin: 4px;
    border-radius: 2px;
    color: #2f80ed;
    background: #d5e6fb;
    border: none;

    font-size: 12px;
  }

  .tag-wrapper {
    display: flex;
    align-items: center;
  }

  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }

  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
  }

  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }

  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }

  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }

  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }

  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: #3c97ff;
    font-size: 16px;
  }
`;

export const TagsContent = styled.div``;

export const Content = styled(Row)``;
