import { v4 } from 'uuid';
import { PaginationBs } from './styles';

function Pagination({
  totalPages,
  actualPage,
  setPage,
}: {
  totalPages: number;
  actualPage: number;
  setPage: (page: number) => void;
}): JSX.Element {
  const items = [];

  if (totalPages === 0) {
    return <></>;
  }
  if (totalPages <= 5 || (totalPages > 5 && actualPage === 1)) {
    if (totalPages > 1) {
      items.push(
        <PaginationBs.Item key={v4().toString()} active={actualPage === 1} onClick={() => setPage(1)}>
          1
        </PaginationBs.Item>
      );
    }
    if (totalPages > 2) {
      items.push(
        <PaginationBs.Item key={v4().toString()} active={actualPage === 2} onClick={() => setPage(2)}>
          2
        </PaginationBs.Item>
      );
    }
    if (totalPages > 3) {
      items.push(
        <PaginationBs.Item key={v4().toString()} active={actualPage === 3} onClick={() => setPage(3)}>
          3
        </PaginationBs.Item>
      );
    }
    if (totalPages === 5) {
      items.push(
        <PaginationBs.Item key={v4().toString()} active={actualPage === 4} onClick={() => setPage(4)}>
          4
        </PaginationBs.Item>
      );
    }
    if (totalPages > 5) {
      items.push(<PaginationBs.Ellipsis />);
    }
  } else if (totalPages > 5) {
    if (actualPage > totalPages - 4) {
      items.push(
        <PaginationBs.Item
          key={v4().toString()}
          active={actualPage === totalPages - 4}
          onClick={() => setPage(totalPages - 4)}
        >
          {totalPages - 4}
        </PaginationBs.Item>
      );
      items.push(
        <PaginationBs.Item
          key={v4().toString()}
          active={actualPage === totalPages - 3}
          onClick={() => setPage(totalPages - 3)}
        >
          {totalPages - 3}
        </PaginationBs.Item>
      );
      items.push(
        <PaginationBs.Item
          key={v4().toString()}
          active={actualPage === totalPages - 2}
          onClick={() => setPage(totalPages - 2)}
        >
          {totalPages - 2}
        </PaginationBs.Item>
      );
      items.push(
        <PaginationBs.Item
          key={v4().toString()}
          active={actualPage === totalPages - 1}
          onClick={() => setPage(totalPages - 1)}
        >
          {totalPages - 1}
        </PaginationBs.Item>
      );
    } else {
      items.push(
        <PaginationBs.Item key={v4().toString()} onClick={() => setPage(actualPage - 1)}>
          {actualPage - 1}
        </PaginationBs.Item>
      );
      items.push(
        <PaginationBs.Item key={v4().toString()} active onClick={() => setPage(actualPage)}>
          {actualPage}
        </PaginationBs.Item>
      );
      items.push(
        <PaginationBs.Item key={v4().toString()} onClick={() => setPage(actualPage + 1)}>
          {actualPage + 1}
        </PaginationBs.Item>
      );
      items.push(<PaginationBs.Ellipsis />);
    }
  }
  return (
    <PaginationBs>
      <PaginationBs.First onClick={() => setPage(1)} />
      {items}
      <PaginationBs.Item
        key={v4().toString()}
        active={actualPage === totalPages}
        onClick={() => setPage(totalPages)}
      >
        {totalPages}
      </PaginationBs.Item>
      <PaginationBs.Last onClick={() => setPage(totalPages)} />
    </PaginationBs>
  );
}

export default Pagination;
