import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const link = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include',
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        feed: {
          keyArgs: ['filters'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
        feedBrand: {
          keyArgs: ['filters'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
        feedFavorites: {
          keyArgs: ['filters'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  link,
  cache,
});

export default client;
