import { useContext } from 'react';
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { ToastContext } from '../../../../contexts/Toast';

type returnType = {
  createTutorial: (
    options?:
      | MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>>
      | undefined
  ) => Promise<FetchResult<unknown, Record<string, unknown>, Record<string, unknown>>>;
  loading: boolean;
};

const CREATE_TUTORIAL = gql`
  mutation Mutation($tutorial: TutorialInput) {
    addTutorial(tutorial: $tutorial) {
      id
      category_id
      title
      description
      url
      created_at
      tutorial_category {
        id
        name
      }
    }
  }
`;

export function useCreateTutorial(): returnType {
  const toastContext = useContext(ToastContext);

  const [createTutorial, { loading }] = useMutation(CREATE_TUTORIAL, {
    update(cache, { data: { addTutorial } }) {
      cache.modify({
        fields: {
          tutorials(existingTutorials = []) {
            const newTutorialRef = cache.writeFragment({
              data: addTutorial,
              fragment: gql`
                fragment NewTutorial on Tutorials {
                  id
                  category_id
                  title
                  description
                  url
                  created_at
                  tutorial_category {
                    id
                    name
                  }
                }
              `,
            });
            return [...existingTutorials, newTutorialRef];
          },
        },
      });
    },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
    onCompleted: () => {
      toastContext.addToast({
        title: 'Sucesso',
        message: 'Tutorial cadastrado com sucesso',
        type: 'success',
      });
    },
  });
  return { createTutorial, loading };
}
