/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetMediaToClone } from 'hooks/graphql/Curatorship/useGetMediaToClone';
import { useGetClonedMediasById } from 'hooks/graphql/Curatorship/useGetClonedMediasById';
import { useGetDwMediaById } from 'hooks/graphql/Curatorship/useGetDwMedia';

import { ButtonAttentionSmall, ButtonSecondarySmall } from '../../../components/Button';
import { Input } from '../../../components/FormComponents/Input';
import { Relevance } from '../../../components/FormComponents/Relevance';
import { Select } from '../../../components/FormComponents/Select';
import { H1 } from '../../../components/Text';
import { useGetMediaByCaptureModule } from '../../../hooks/graphql/Admin/Media/useGetMediaByCaptureModule';
import {
  Container,
  Content,
  Header,
  Wrapper,
  LeftContainer,
  FormContainer,
  FormBlock,
  Divisory,
  ButtonContainer,
  MidiaContainer,
  ButtonPrimarySmallCustom,
  CloneMediaContainer,
} from './styles';

import { ToastContext } from '../../../contexts/Toast';
import { Tags } from '../../../components/FormComponents/Tags';

import { useGetInterestTopics } from '../../../hooks/graphql/Admin/InterestTopics/useGetInterestTopics';
import { ShowMedia } from '../../../components/FormComponents/ShowMedia';
import { IrregularMediaModal } from '../../../components/Curatorship/IrregularMediaModal';
import { useUpdateDwMedia } from '../../../hooks/graphql/Curatorship/useUpdateDwMedia';
import { curatorshipYupValidator } from '../../../utils/validators/curatorshipYupValidator';
import { CloneMediaCard } from '../../../components/Curatorship/CloneMediaCard';
import DwMedia from '../../../@types/dwMedia';

interface ITag {
  id: string;
  text: string;
}

interface IFormData {
  mediaId: string;
  subject: string;
  interest_topic_id: string;
  intentionTags: ITag[];
  concreteTags: ITag[];
  concreteTagsValences: number[];
  receivedDate: string;
  qtyReceived: number;
  qtySent: number;
  sourceName: string;
  relevance: number;
}

interface ICloneFormData {
  finalDate: string;
  initialDate: string;
  tags: string;
}

interface IUpdateCuratorshipVariables {
  dwMediaId: string;
  dwMedia: {
    mediaMain?: string;
    subject: string;
    interest_topic_id: string;
    intent_tags: string[];
    concrete_tags: string[];
    concrete_tags_valences: number[];
    relevance: number;
  };
}

export function MediaEdit(): JSX.Element {
  const [cloneMediaFilters, setCloneMediaFilters] = useState({
    tags: [''],
    initialDate: '',
    finalDate: '',
  });

  const [mediaMain, setMediaMain] = useState('');

  const { loading, data: media } = useGetMediaByCaptureModule();
  const { data: interestedTopics } = useGetInterestTopics();
  const { updateDwMedia } = useUpdateDwMedia();
  const { data: mediaToClone } = useGetMediaToClone(
    cloneMediaFilters.tags,
    cloneMediaFilters.initialDate,
    cloneMediaFilters.finalDate
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const toastContext = useContext(ToastContext);

  const { id } = useParams();

  const { data: dwMediaApi, loading: loadingDwMediaApi } = useGetDwMediaById(id || '');
  const { data: clonedMedias } = useGetClonedMediasById(id || '');

  const methods = useForm<IFormData>({ resolver: yupResolver(curatorshipYupValidator) });

  const cloneMediaMethods = useForm<ICloneFormData>();

  const navigate = useNavigate();

  useEffect(() => {
    cloneMediaMethods.setValue('initialDate', '2021-01-01');
    cloneMediaMethods.setValue('finalDate', new Date().toLocaleDateString('en-CA'));
  }, [cloneMediaMethods]);

  const updateData = useCallback(async (): Promise<void> => {
    try {
      // await updateMedia({
      //   variables: {
      //     captureModuleId: id,
      //   },
      // });
    } catch (err) {
      navigate('/curatorship');
    }
  }, [navigate]);

  useEffect(() => {
    methods.setValue('receivedDate', moment(media?.received_date).format('DD/MM/YYYY'));
    methods.setValue('qtyReceived', media?.qty_received);
    methods.setValue('qtySent', media?.qty_sent);
    methods.setValue('sourceName', media?.source.name);
  }, [media, methods]);

  useEffect(() => {
    if (!dwMediaApi) return;
    if (!dwMediaApi.id) return;
    if (!loadingDwMediaApi && !dwMediaApi.id) {
      navigate('/curatorship');
    }

    methods.setValue('receivedDate', moment(dwMediaApi?.media_received_at).format('DD/MM/YYYY'));
    methods.setValue('qtyReceived', dwMediaApi?.media_qty_received);
    methods.setValue('qtySent', dwMediaApi?.media_qty_sent);
    methods.setValue('sourceName', dwMediaApi?.source?.name || '');
    methods.setValue('subject', dwMediaApi?.subject);
    methods.setValue('interest_topic_id', dwMediaApi?.interest_topic_id);
    if (dwMediaApi.intent_tags.length > 0) {
      methods.setValue(
        'intentionTags',
        dwMediaApi?.intent_tags?.map((tag) => {
          return {
            id: tag,
            text: tag,
          };
        })
      );
    }

    if (dwMediaApi.concrete_tags.length > 0) {
      methods.setValue(
        'concreteTags',
        dwMediaApi?.concrete_tags?.map((tag) => {
          return {
            id: tag,
            text: tag,
          };
        })
      );

      methods.setValue(
        'concreteTagsValences',
        dwMediaApi.concrete_tags_valences.slice(0, dwMediaApi.concrete_tags.length)
      );

      methods.setValue('relevance', dwMediaApi?.relevance);
    }
  }, [dwMediaApi, loadingDwMediaApi, methods, navigate]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      await updateData();
    }
    loadData();
  }, [updateData]);

  async function onSubmit(data: IFormData): Promise<void> {
    if (!id) return;

    try {
      const variables: IUpdateCuratorshipVariables = {
        dwMediaId: id,
        dwMedia: {
          subject: data.subject,
          interest_topic_id: data.interest_topic_id,
          intent_tags: data.intentionTags.map((tag) => tag.text.toLowerCase()),
          concrete_tags: data.concreteTags.map((tag) => tag.text.toLowerCase()),
          concrete_tags_valences: data.concreteTagsValences,
          relevance: Number(data.relevance),
        },
      };

      if (mediaMain) {
        variables.dwMedia.mediaMain = mediaMain;
      }

      await updateDwMedia({
        variables,
      });
    } catch (err) {
      toastContext.addToast({
        title: 'Erro',
        message: 'Ocorreu um erro ao enviar a curadoria',
        type: 'error',
      });
    }
  }

  async function onSearchMediaToClone(data: ICloneFormData): Promise<void> {
    setCloneMediaFilters({
      finalDate: data.finalDate,
      initialDate: data.initialDate,
      tags: data.tags.split(' '),
    });
  }

  const cloneMedia = useCallback(
    (dwMedia: DwMedia) => {
      methods.setValue('subject', dwMedia.subject);
      methods.setValue('interest_topic_id', dwMedia.interest_topic_id);
      methods.setValue('relevance', dwMedia.relevance);
      methods.setValue(
        'intentionTags',
        dwMedia.intent_tags.map((tag) => {
          return {
            id: tag,
            text: tag,
          };
        })
      );
      methods.setValue(
        'concreteTags',
        dwMedia.concrete_tags.map((tag) => {
          return {
            id: tag,
            text: tag,
          };
        })
      );
      methods.setValue('concreteTagsValences', dwMedia.concrete_tags_valences);

      setMediaMain(dwMedia.id);
      document.getElementById('root')?.scrollIntoView({ behavior: 'smooth' });
    },
    [methods]
  );

  return (
    <>
      <Wrapper>
        <Container>
          <Header>
            <H1>Edição de mídia</H1>
          </Header>
          <Content>
            <LeftContainer>
              <MidiaContainer>
                {!loading && <ShowMedia media={dwMediaApi?.media_url} text={dwMediaApi?.media_text} />}
              </MidiaContainer>
              <ButtonContainer>
                <ButtonAttentionSmall onClick={() => setIsModalVisible(true)}>
                  Mídia irregular
                </ButtonAttentionSmall>
              </ButtonContainer>
            </LeftContainer>
            <FormProvider {...methods}>
              <FormContainer>
                <FormBlock>
                  <Input name="subject" label="Assunto da mídia" />
                  <Select
                    name="interest_topic_id"
                    label="Tema da mídia"
                    options={interestedTopics.map((interestedTopic) => {
                      return {
                        value: interestedTopic.id,
                        label: interestedTopic.name,
                      };
                    })}
                  />
                </FormBlock>
                <FormBlock>
                  <Tags name="concreteTags" label="Tags concretas" valenceName="concreteTagsValences" />
                  <Tags name="intentionTags" label="Tags de intenção" />
                </FormBlock>
                <FormBlock>
                  <Relevance label="Relevância" name="relevance" />
                </FormBlock>
                <Divisory />
                <FormBlock>
                  <Input label="Fonte da mídia" name="sourceName" readOnly />
                  <Input label="Data do recebimento" name="receivedDate" readOnly />
                </FormBlock>

                <FormBlock>
                  <Input label="Número de recebimentos do respondente" name="qtyReceived" readOnly />
                  <Input label="Número de encaminhamentos do respondente" name="qtySent" readOnly />
                </FormBlock>
                <ButtonContainer margin="1rem 0 1rem 0">
                  <ButtonSecondarySmall onClick={methods.handleSubmit((data) => onSubmit(data))}>
                    Salvar
                  </ButtonSecondarySmall>
                </ButtonContainer>
              </FormContainer>
            </FormProvider>
          </Content>
        </Container>

        <Container>
          <Header>
            <H1>Mídias a serem clonadas</H1>
          </Header>
          <Content>
            <FormProvider {...cloneMediaMethods}>
              <FormContainer>
                <FormBlock>
                  <Input label="Busca por temos" name="tags" innerLabel />
                  <Input label="Data inicial" name="initialDate" innerLabel type="date" />
                  <Input label="Data final" name="finalDate" innerLabel type="date" />
                  <ButtonPrimarySmallCustom onClick={cloneMediaMethods.handleSubmit(onSearchMediaToClone)}>
                    BUSCAR
                  </ButtonPrimarySmallCustom>
                </FormBlock>
              </FormContainer>
            </FormProvider>
          </Content>
          <CloneMediaContainer>
            <>
              <>
                {clonedMedias.length > 0 &&
                  clonedMedias.map((dwMedia) => (
                    <CloneMediaCard
                      key={dwMedia.id}
                      dwMedia={dwMedia}
                      cloneMediaFunc={cloneMedia}
                      isCloned={dwMedia.media_main === id}
                    />
                  ))}
              </>
              <>
                {mediaToClone.length > 0 &&
                  mediaToClone?.map((dwMedia) => (
                    <CloneMediaCard
                      key={dwMedia.id}
                      dwMedia={dwMedia}
                      cloneMediaFunc={cloneMedia}
                      isCloned={dwMedia.media_main === id}
                    />
                  ))}
              </>
            </>
          </CloneMediaContainer>
        </Container>
      </Wrapper>

      <IrregularMediaModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        mediaId={dwMediaApi?.id}
        isEdit
      />
    </>
  );
}
