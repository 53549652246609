import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px;

  .col-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    min-height: 60px;

    @media (max-width: 992px) {
      flex-direction: column;

      .btn {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
  }

  .accordion-button {
    padding: 8px;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: ${({ theme }) => theme.borderRadius.medium}px;
    margin-top: 8px;

    &:focus {
      box-shadow: 0 0 0 0;
    }
  }

  .btn {
    width: 25%;
    display: block;
    margin: auto;
    @media (max-width: 992px) {
      width: 80%;
    }
  }
`;

export const Badge = styled.span`
  background-color: ${({ theme }) => theme.colors.secondary.main};
  color: ${({ theme }) => theme.colors.background};
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin-left: 8px;
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 24px;
  padding: 24px;
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
`;

export const ColContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const Col = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 24px;
  width: calc(50% - 12px);
  display: block;
  margin: 0 12px 0 0;
  border-radius: ${({ theme }) => theme.borderRadius.large}px;

  & + & {
    margin: 0 0 0 12px;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin: 0;
    & + & {
      margin: 24px 0 0 0;
    }
  }
`;

export const HR = styled.hr`
  border: 2px solid ${({ theme }) => theme.colors.neutral[1]};
`;
