import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ToastContext } from '../../../../contexts/Toast';
import TutorialCategory from '../../../../@types/tutorialCategory';

const GET_TUTORIAL_CATEGORIES = gql`
  query TutorialCategories {
    tutorialCategories {
      id
      name
    }
  }
`;

export function useGetTutorialCategories(): { data: TutorialCategory[]; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_TUTORIAL_CATEGORIES, {
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: [], loading: false };
  }
  return { data: data ? data.tutorialCategories : ([] as TutorialCategory[]), loading };
}

const GET_TUTORIAL_CATEGORY = gql`
  query Query($tutorialCategoryId: UUID!) {
    tutorialCategory(id: $tutorialCategoryId) {
      id
      name
    }
  }
`;

export function useGetTutorialCategory(id: string | undefined): { data: TutorialCategory; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_TUTORIAL_CATEGORY, {
    variables: { tutorialCategoryId: id },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: {} as TutorialCategory, loading: false };
  }
  return { data: data ? data.tutorialCategory : ({} as TutorialCategory), loading };
}
