export const quantityItensPerPageDefault = 5;

export const MegabyteInBytes = 1024 * 1024;

export const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp'];

export const FEED_WINDOW_SIZE = 20;

export const BrazilStates = [
  {
    id: 'AC',
    name: 'Acre',
    initials: 'AC',
    region: 'Norte',
  },
  {
    id: 'AL',
    name: 'Alagoas',
    initials: 'AL',
    region: 'Nordeste',
  },
  {
    id: 'AP',
    name: 'Amapá',
    initials: 'AP',
    region: 'Norte',
  },
  {
    id: 'AM',
    name: 'Amazonas',
    initials: 'AM',
    region: 'Norte',
  },
  {
    id: 'BA',
    name: 'Bahia',
    initials: 'BA',
    region: 'Nordeste',
  },
  {
    id: 'CE',
    name: 'Ceará',
    initials: 'CE',
    region: 'Nordeste',
  },
  {
    id: 'DF',
    name: 'Distrito Federal',
    initials: 'DF',
    region: 'Centro-Oeste',
  },
  {
    id: 'ES',
    name: 'Espírito Santo',
    initials: 'ES',
    region: 'Sudeste',
  },
  {
    id: 'GO',
    name: 'Goiás',
    initials: 'GO',
    region: 'Centro-Oeste',
  },
  {
    id: 'MA',
    name: 'Maranhão',
    initials: 'MA',
    region: 'Nordeste',
  },
  {
    id: 'MT',
    name: 'Mato Grosso',
    initials: 'MT',
    region: 'Centro-Oeste',
  },
  {
    id: 'MS',
    name: 'Mato Grosso do Sul',
    initials: 'MS',
    region: 'Centro-Oeste',
  },
  {
    id: 'MG',
    name: 'Minas Gerais',
    initials: 'MG',
    region: 'Sudeste',
  },
  {
    id: 'PA',
    name: 'Pará',
    initials: 'PA',
    region: 'Norte',
  },
  {
    id: 'PB',
    name: 'Paraíba',
    initials: 'PB',
    region: 'Nordeste',
  },
  {
    id: 'PR',
    name: 'Paraná',
    initials: 'PR',
    region: 'Sul',
  },
  {
    id: 'PE',
    name: 'Pernambuco',
    initials: 'PE',
    region: 'Nordeste',
  },
  {
    id: 'PI',
    name: 'Piauí',
    initials: 'PI',
    region: 'Nordeste',
  },
  {
    id: 'RJ',
    name: 'Rio de Janeiro',
    initials: 'RJ',
    region: 'Sudeste',
  },
  {
    id: 'RN',
    name: 'Rio Grande do Norte',
    initials: 'RN',
    region: 'Nordeste',
  },
  {
    id: 'RS',
    name: 'Rio Grande do Sul',
    initials: 'RS',
    region: 'Sul',
  },
  {
    id: 'RO',
    name: 'Rondônia',
    initials: 'RO',
    region: 'Norte',
  },
  {
    id: 'RR',
    name: 'Roraima',
    initials: 'RR',
    region: 'Norte',
  },
  {
    id: 'SC',
    name: 'Santa Catarina',
    initials: 'SC',
    region: 'Sul',
  },
  {
    id: 'SP',
    name: 'São Paulo',
    initials: 'SP',
    region: 'Sudeste',
  },
  {
    id: 'SE',
    name: 'Sergipe',
    initials: 'SE',
    region: 'Nordeste',
  },
  {
    id: 'TO',
    name: 'Tocantins',
    initials: 'TO',
    region: 'Norte',
  },
];
