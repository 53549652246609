/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/require-default-props */
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { P16 } from '../../Text';
import { MediaContainer, TextContainer } from './styles';

interface IShowMedia {
  media?: string;
  text?: string;
}

function getUrlExtension(url: string): string | undefined {
  return url.split(/[#?]/)[0].split('.').pop()?.trim();
}

const imageExtensions = ['jpg', 'jpeg', 'png', 'bmp', 'webp', 'svg', 'gif', 'jfif'];
const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'flv', 'webm'];
const audioExtensions = ['mp3', 'wav', 'ogg', 'flac'];

export function ShowMedia({ media = '', text = '' }: IShowMedia): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState(false);

  function openModal(): void {
    setIsModalVisible(true);
  }

  if (text) {
    return (
      <TextContainer>
        <P16>{text}</P16>
      </TextContainer>
    );
  }

  if (media) {
    const mediaExtension = getUrlExtension(media);

    if (!mediaExtension) return <></>;

    if (videoExtensions.includes(mediaExtension)) {
      return (
        <MediaContainer>
          <video src={media} controls />
        </MediaContainer>
      );
    }

    if (imageExtensions.includes(mediaExtension)) {
      return (
        <>
          <MediaContainer>
            <img className="minifiedImg" src={media} alt="Media" onClick={openModal} />
          </MediaContainer>
          <Modal
            show={isModalVisible}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setIsModalVisible(false)}
            size="lg"
          >
            <MediaContainer originalSize>
              <img src={media} alt="Media" />
            </MediaContainer>
          </Modal>
        </>
      );
    }

    if (audioExtensions.includes(mediaExtension)) {
      return (
        <MediaContainer>
          <audio src={media} controls />
        </MediaContainer>
      );
    }
  }

  return <></>;
}
