/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { WithContext as ReactTags } from 'react-tag-input';
import { TagsContainer, Container, Content, TagsContent } from './styles';

interface ITags {
  id: string;
  text: string;
}

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

interface ITagsProps {
  name: string;
  label: string;
}

export function TagsMediaClone({ name, label }: ITagsProps): JSX.Element {
  const { register, setValue, watch } = useFormContext();

  const watchTags = watch(name);

  useEffect(() => {
    register(name, {
      value: [],
    });
  }, [name, register, setValue]);

  const handleDelete = (id: number): void => {
    setValue(
      name,
      watchTags.filter((tag: ITags, index: number) => index !== id)
    );
  };

  const handleAddition = (tag: ITags): void => {
    setValue(name, [...watchTags, tag]);
  };

  const handleContainerClick = (): void => {
    const inputElement: HTMLInputElement | null = document.querySelector(
      `.container__${name} .ReactTags__tagInputField`
    );
    inputElement?.focus();
  };

  return (
    <Container className={`container__${name}`}>
      <Content>
        <TagsContainer>
          <TagsContent onClick={handleContainerClick}>
            <ReactTags
              tags={watchTags}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              inputFieldPosition="bottom"
              allowDragDrop={false}
              placeholder={label}
            />
          </TagsContent>
        </TagsContainer>
      </Content>
    </Container>
  );
}
