import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonSecondarySmall } from '../../../../components/Button';
import { H4 } from '../../../../components/Text';
import { useGetTutorialCategories } from '../../../../hooks/graphql/Admin/TutorialCategories/useGetTutorialCategories';
import { Wrapper } from './styles';
import { useGetTutorial } from '../../../../hooks/graphql/Admin/Tutorials/useGetTutorials';
import { useUpdateTutorial } from '../../../../hooks/graphql/Admin/Tutorials/useUpdateTutorial';

interface IMessage {
  field: string;
  message: string;
}

function EditTutorial(): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');

  const [category, setCategory] = useState('');
  const [messages, setMessages] = useState<IMessage[]>([]);
  const { data: tutorialCategories } = useGetTutorialCategories();

  const { loading: findLoading, data: findData } = useGetTutorial(params.id);

  useEffect(() => {
    if (!findLoading && findData) {
      setTitle(findData.title);
      setDescription(findData.description);
      setUrl(findData.url);
      if (findData.category_id) {
        setCategory(findData.category_id);
      }
    }
  }, [findData, findLoading]);

  const addMessage = (field: string, message: string): void => {
    setMessages((prevState) => [...prevState, { field, message }]);
  };

  const removeMessage = (field: string): void => {
    setMessages((prevState) => prevState.filter((message) => message.field !== field));
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value);

    if (!event.target.value) {
      addMessage('title', 'Título é obrigatório');
    } else {
      removeMessage('title');
    }
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDescription(event.target.value);

    if (!event.target.value) {
      addMessage('description', 'Descrição é obrigatória');
    } else {
      removeMessage('description');
    }
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUrl(event.target.value);
    removeMessage('url');

    if (!event.target.value) {
      addMessage('url', 'Url é obrigatória');
      return;
    }

    if (event.target.value.length !== 11) {
      addMessage('url', 'O código do vídeo deve ter 11 caracteres');
      return;
    }

    removeMessage('url');
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setCategory(event.target.value);

    if (!event.target.value) {
      addMessage('category', 'Categoria é obrigatória');
    } else {
      removeMessage('category');
    }
  };
  const getMessagesByField = (field: string): JSX.Element[] => {
    return messages
      .filter((message) => message.field === field)
      .map((message) => (
        <Form.Text className="bad-message" key={message.message}>
          {message.message}
        </Form.Text>
      ));
  };

  const { updateTutorial } = useUpdateTutorial();

  function checkInputsValidation(): boolean {
    let hasErrors = false;
    removeMessage('title');
    removeMessage('description');
    removeMessage('url');
    removeMessage('category');

    if (!title) {
      addMessage('title', 'Título é obrigatório');
      hasErrors = true;
    }

    if (!category) {
      addMessage('category', 'Categoria é obrigatória');
      hasErrors = true;
    }

    if (!description) {
      addMessage('description', 'Descrição é obrigatória');
      hasErrors = true;
    }

    if (!url) {
      addMessage('url', 'Link é obrigatório');
      hasErrors = true;
    }

    return hasErrors;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const hasErrors = checkInputsValidation();

    if (hasErrors) return;

    if (messages.length === 0) {
      updateTutorial({
        variables: {
          updateTutorialId: params.id,
          tutorial: { title, description, url, category_id: category },
        },
      });
      navigate('../');
    }
  };

  return (
    <Wrapper>
      <div className="col-header">
        <H4>Edição de Tutorial</H4>
      </div>
      <Form>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Nome</Form.Label>
          <Form.Control type="text" placeholder="Título" value={title} onChange={handleTitleChange} />
          {getMessagesByField('name')}
        </Form.Group>
        <Form.Group className="mb-3" controlId="category">
          <Form.Label>Categoria</Form.Label>
          <Form.Select value={category} onChange={handleCategoryChange}>
            <option value="">Selecione uma categoria</option>

            {tutorialCategories.map((tutorialCategory) => (
              <option key={tutorialCategory.id} value={tutorialCategory.id}>
                {tutorialCategory.name}
              </option>
            ))}
          </Form.Select>
          {getMessagesByField('category')}
        </Form.Group>
        <Form.Group className="mb-3" controlId="description">
          <Form.Label>Desrição</Form.Label>
          <Form.Control
            type="text"
            placeholder="Descrição"
            value={description}
            onChange={handleDescriptionChange}
          />
          {getMessagesByField('description')}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Link do vídeo</Form.Label>
          <br />
          <Form.Text className="input-group-text w-50 d-inline-block" id="fixed-text">
            https://www.youtube.com/watch?v=
          </Form.Text>
          <Form.Control
            className="w-50 d-inline-block"
            type="text"
            value={url}
            onChange={handleUrlChange}
            id="input-text"
          />
          {getMessagesByField('url')}
        </Form.Group>

        <ButtonSecondarySmall onClick={handleSubmit}>SALVAR</ButtonSecondarySmall>
      </Form>
    </Wrapper>
  );
}

export default EditTutorial;
