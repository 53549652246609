import { useGetAuthenticated } from 'hooks/graphql/AuthCheck/useGetAuthenticated';
import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContext } from '../../contexts/Toast';
import { UserContext } from '../../contexts/User';

function Auth({ roles, children }: { roles: string[]; children: JSX.Element }): JSX.Element {
  const user = useContext(UserContext);
  const toast = useContext(ToastContext);
  const navigate = useNavigate();
  const { getIsAuthenticated, data } = useGetAuthenticated();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!user.user) {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getIsAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!data) return;
    if (data.code === 401) {
      navigate('/login');
      localStorage.removeItem('@hiddeninsights/user');
    }
  }, [data, navigate]);

  useEffect(() => {
    if (user.user && !roles?.includes(user?.user?.role || 'NONE')) {
      navigate(user?.user?.role === 'CURATOR' ? '/curator' : '/feed');
      toast.addToast({
        type: 'error',
        title: 'Erro',
        message: 'Você não tem autorização para acessar esta página',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
}

export default Auth;
