import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { NavLink, Outlet } from 'react-router-dom';
import { H1, P16 } from '../../components/Text';
import { Container, Content, Header, Wrapper } from './styles';

function Profile(): JSX.Element {
  return (
    <Wrapper>
      <Container>
        <Header>
          <Col lg={12}>
            <H1>Perfil</H1>
            <P16>
              Selecione suas preferências para que o Hidden Insights mostre os melhores resultados para você!
            </P16>
          </Col>
        </Header>
        <Content>
          <Col lg={3}>
            <Nav className="flex-column" defaultActiveKey="profile">
              <Nav.Item>
                <NavLink to="/profile/profile" className="profile nav-link">
                  Perfil
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to="/profile/topics" className="topics nav-link">
                  Temas de Interesse
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to="/profile/brands" className="brands nav-link">
                  Marcas favoritas
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to="/profile/keywords" className="keywords nav-link">
                  Palavras-chave
                </NavLink>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={9}>
            <Outlet />
          </Col>
        </Content>
      </Container>
    </Wrapper>
  );
}

export default Profile;
