import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Tutorial from '../../../@types/tutorial';
import { Description, Infos, ThumbContainer, Title } from './styles';

interface ITutorialModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
  selectedTutorial: Tutorial;
}

export function TutorialModal({
  isModalVisible,
  setIsModalVisible,
  selectedTutorial,
}: ITutorialModalProps): JSX.Element {
  return (
    <Modal
      show={isModalVisible}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setIsModalVisible(false)}
      size="lg"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <ThumbContainer>
          <iframe
            width="100%"
            height="100%"
            style={{ width: '100%', height: '100%' }}
            src={`https://www.youtube.com/embed/${selectedTutorial.url}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </ThumbContainer>
        <Infos>
          {moment(selectedTutorial.created_at).locale('pt-br').format('DD [de] MMM[.]')} |{' '}
          {selectedTutorial.tutorial_category?.name}
        </Infos>
        <Title>{selectedTutorial.title}</Title>
        <Description>{selectedTutorial.description}</Description>
      </Modal.Body>
    </Modal>
  );
}
