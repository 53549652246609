import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import { useLikeMedia } from 'hooks/graphql/Feed/useLikeMedia';
import { Toast } from './styles';

import likeIcon from '../../../assets/images/icon/feedback/like.svg';

function LikeToast({ mediaId }: { mediaId?: string }): JSX.Element {
  const [show, setShow] = useState(true);

  const { likeMedia } = useLikeMedia();

  const handleUnlike = (): void => {
    setShow(false);

    likeMedia({
      variables: {
        mediaId,
        operation: 'UNLIKE',
      },
    });
  };

  return (
    <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide>
      <Toast.Body className="w-100 d-flex align-items-center">
        <div className="w-100 d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-center">
            <Image src={likeIcon} alt="Curtida" />
          </div>
          <div id="text-container">
            <p>Conteúdo adicionado aos favoritos!</p>
          </div>
        </div>
        <button type="button" className="btn" onClick={() => handleUnlike()}>
          DESFAZER
        </button>
      </Toast.Body>
    </Toast>
  );
}

LikeToast.defaultProps = {
  mediaId: '',
};

export default React.memo(LikeToast);
