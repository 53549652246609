/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useQuery } from '@apollo/client';
import { ToastContext } from 'contexts/Toast';
import { useContext } from 'react';
import DwMedia from '../../../@types/dwMedia';

const GET_MEDIA_TO_CLONE = gql`
  query Query($tags: [String], $initialDate: DateTime, $finalDate: DateTime) {
    getMediaToClone(tags: $tags, initial_date: $initialDate, final_date: $finalDate) {
      id
      media_id
      media_url
      media_text
      media_qty_received
      subject
      media_received_at
      intent_tags
      concrete_tags
      relevance
      concrete_tags_valences
      interest_topic_id
    }
  }
`;

export function useGetMediaToClone(
  tags: string[] | undefined,
  initialDate: string,
  finalDate: string
): { data: DwMedia[]; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_MEDIA_TO_CLONE, {
    variables: { tags, initialDate, finalDate },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: {} as DwMedia[], loading: false };
  }
  return { data: data ? data.getMediaToClone : ({} as DwMedia[]), loading };
}
