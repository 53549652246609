import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ToastContext } from '../../../../contexts/Toast';
import Tutorial from '../../../../@types/tutorial';

const GET_TUTORIALS = gql`
  query Tutorials {
    tutorials {
      id
      category_id
      title
      description
      url
      created_at
      tutorial_category {
        id
        name
      }
    }
  }
`;

export function useGetTutorials(): { data: Tutorial[]; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_TUTORIALS, {
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: [], loading: false };
  }
  return { data: data ? data.tutorials : ([] as Tutorial[]), loading };
}

const GET_TUTORIAL = gql`
  query Tutorial($tutorialId: UUID!) {
    tutorial(id: $tutorialId) {
      id
      category_id
      title
      description
      url
    }
  }
`;

export function useGetTutorial(id: string | undefined): { data: Tutorial; loading: boolean } {
  const toastContext = useContext(ToastContext);

  const { data, loading, error } = useQuery(GET_TUTORIAL, {
    variables: { tutorialId: id },
    onError: (e) => {
      toastContext.addToast({ title: 'Erro', message: e.message, type: 'error' });
    },
  });
  if (error) {
    return { data: {} as Tutorial, loading: false };
  }

  return { data: data ? data.tutorial : ({} as Tutorial), loading };
}
