/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { P16 } from 'components/Text';
import { useGetTagsSuggestions } from 'hooks/graphql/Curatorship/useGetTagsSuggestions';
import { useEffect, useState } from 'react';
import { FormSelect } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { WithContext as ReactTags } from 'react-tag-input';
import { TagsContainer, Container, Content, SelectContainer, TagsContent } from './styles';

interface ITags {
  id: string;
  text: string;
}

interface ITagSuggestion {
  term: string;
  type: 'CONCRETE' | 'INTENT';
}

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

interface ITagsProps {
  name: string;
  label: string;
  valenceName?: string;
  showSuggestions?: 'CONCRETE' | 'INTENT' | 'ALL';
}

export function Tags({ name, label, valenceName, showSuggestions }: ITagsProps): JSX.Element {
  const { register, setValue, watch } = useFormContext();

  const watchTags = watch(name);

  let watchValences: number[] = [];

  if (valenceName) watchValences = watch(valenceName);

  useEffect(() => {
    register(name, {
      value: [],
    });

    if (valenceName) {
      register(valenceName, {
        value: [],
      });
    }
  }, [name, register, setValue, valenceName]);

  const handleDelete = (id: number): void => {
    setValue(
      name,
      watchTags.filter((tag: ITags, index: number) => index !== id)
    );

    if (valenceName) {
      setValue(
        valenceName,
        watchValences.filter((valence: number, index: number) => index !== id)
      );
    }
  };

  const handleAddition = (tag: ITags): void => {
    setValue(name, [...watchTags, tag]);

    if (valenceName && watchValences) {
      setValue(valenceName, [...watchValences, 0]);
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number): void => {
    if (!valenceName) return;
    const valencesMirror = [...watchValences];
    valencesMirror[index] = Number(e.target.value);

    setValue(valenceName, valencesMirror);
  };

  const handleContainerClick = (): void => {
    const inputElement: HTMLInputElement | null = document.querySelector(
      `.container__${name} .ReactTags__tagInputField`
    );
    inputElement?.focus();
  };

  const { data: tagsList, loading: loadingTagsList } = useGetTagsSuggestions();

  const [suggestions, setSuggestions] = useState<ITags[]>([]);

  useEffect(() => {
    if (tagsList && !loadingTagsList) {
      if (showSuggestions === 'CONCRETE') {
        setSuggestions(
          tagsList
            .filter((tag: ITagSuggestion) => tag.type === 'CONCRETE')
            .map((tag: ITagSuggestion) => ({
              id: tag.term,
              text: tag.term,
            }))
        );
      }
      if (showSuggestions === 'INTENT') {
        setSuggestions(
          tagsList

            .filter((tag: ITagSuggestion) => tag.type === 'INTENT')
            .map((tag: ITagSuggestion) => ({
              id: tag.term,
              text: tag.term,
            }))
        );
      }

      if (showSuggestions === 'ALL') {
        setSuggestions(
          tagsList.map((tag: ITagSuggestion) => ({
            id: tag.term,
            text: tag.term,
          }))
        );
      }
    }
  }, [tagsList, loadingTagsList, showSuggestions]);

  return (
    <Container className={`container__${name}`}>
      <Content>
        <P16>{label}</P16>
        <TagsContainer>
          <TagsContent onClick={handleContainerClick}>
            <ReactTags
              tags={watchTags}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              inputFieldPosition="bottom"
              allowDragDrop={false}
              placeholder="Escreva cada palavra-chave e pressione 'Enter'"
              suggestions={suggestions}
            />
          </TagsContent>

          {!!valenceName && (
            <>
              <P16>Valências</P16>
              {watchValences?.map((valence, index) => {
                return (
                  <SelectContainer key={watchTags[index].id}>
                    <span>{watchTags[index].text}</span>
                    <FormSelect value={valence} onChange={(e) => handleSelectChange(e, index)}>
                      <option value={-1}>Negativa</option>
                      <option value={0}>Neutra</option>
                      <option value={1}>Positiva</option>
                    </FormSelect>
                  </SelectContainer>
                );
              })}
            </>
          )}
        </TagsContainer>
      </Content>
    </Container>
  );
}
