/* eslint-disable prettier/prettier */

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { MediaEdit } from 'pages/Curatorship/MediaEdit';
import CreateTutorial from './pages/Admin/Tutorials/Create';
import EditTutorial from './pages/Admin/Tutorials/Edit';
import { MediaAnalisys } from './pages/Curatorship/MediaAnalysis';
import NoOperationTutorials from './pages/Admin/Tutorials/NoOperation';
import Tutorials from './pages/Tutorials';
import List from './pages/Tutorials/list';
import TutorialsAdmin from './pages/Admin/Tutorials';
import Auth from './components/Auth';
import Base from './pages/Base';
import Curatorship from './pages/Curatorship';
import Onboarding from './pages/Onboarding';
import Profile from './pages/Profile';

const CLIENT = 'CLIENT';
const CLIENT_ADMIN = 'CLIENT_ADMIN';
const CURATOR = 'CURATOR';
const ADMIN = 'ADMIN';

const CaptureModules = React.lazy(() => import('./pages/Admin/CaptureModules'));
const NoOperationCaptureModules = React.lazy(() => import('./pages/Admin/CaptureModules/NoOperation'));
const CreateCaptureModule = React.lazy(() => import('./pages/Admin/CaptureModules/Create'));
const EditCaptureModule = React.lazy(() => import('./pages/Admin/CaptureModules/Edit'));
const QuestionsCaptureModule = React.lazy(() => import('./pages/Admin/CaptureModules/Questions'));
const ProfileProfile = React.lazy(() => import('./pages/Profile/Profile'));
const BrandsProfile = React.lazy(() => import('./pages/Profile/Brands'));
const InterestTopicsProfile = React.lazy(() => import('./pages/Profile/InterestTopics'));
const KeywordsProfile = React.lazy(() => import('./pages/Profile/Keywords'));
const Login = React.lazy(() => import('./pages/Login'));
const ProfileOnboarding = React.lazy(() => import('./pages/Onboarding/Profile'));
const InterestTopicsOnboarding = React.lazy(() => import('./pages/Onboarding/InterestTopics'));
const BrandsOnboarding = React.lazy(() => import('./pages/Onboarding/Brands'));
const KeywordsOnboarding = React.lazy(() => import('./pages/Onboarding/Keywords'));
const Companies = React.lazy(() => import('./pages/Admin/Companies'));
const NoOperationCompanies = React.lazy(() => import('./pages/Admin/Companies/NoOperation'));
const CreateCompany = React.lazy(() => import('./pages/Admin/Companies/Create'));
const EditCompany = React.lazy(() => import('./pages/Admin/Companies/Edit'));
const Contracts = React.lazy(() => import('./pages/Admin/Companies/Contracts'));
const CreateContract = React.lazy(() => import('./pages/Admin/Companies/Contracts/Create'));
const EditContract = React.lazy(() => import('./pages/Admin/Companies/Contracts/Edit'));
const UsersContract = React.lazy(() => import('./pages/Admin/Companies/Contracts/Users'));
const Admin = React.lazy(() => import('./pages/Admin'));
const Users = React.lazy(() => import('./pages/Admin/Users'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const CreateUser = React.lazy(() => import('./pages/Admin/Users/Create'));
const EditUser = React.lazy(() => import('./pages/Admin/Users/Edit'));
const InviteUser = React.lazy(() => import('./pages/Admin/Users/Invite'));
const NoOperationUser = React.lazy(() => import('./pages/Admin/Users/NoOperation'));
const TutorialCategories = React.lazy(() => import('./pages/Admin/TutorialCategories'));
const NoOperationTutorialCategories = React.lazy(() => import('./pages/Admin/TutorialCategories/NoOperation'));
const CreateTutorialCategory = React.lazy(() => import('./pages/Admin/TutorialCategories/Create'));
const EditTutorialCategory = React.lazy(() => import('./pages/Admin/TutorialCategories/Edit'));
const BrandCategories = React.lazy(() => import('./pages/Admin/BrandCategories'));
const NoOperationBrandCategories = React.lazy(() => import('./pages/Admin/BrandCategories/NoOperation'));
const CreateBrandCategory = React.lazy(() => import('./pages/Admin/BrandCategories/Create'));
const EditBrandCategory = React.lazy(() => import('./pages/Admin/BrandCategories/Edit'));
const InterestTopics = React.lazy(() => import('./pages/Admin/InterestTopics'));
const NoOperationInterestTopics = React.lazy(() => import('./pages/Admin/InterestTopics/NoOperation'));
const CreateInterestTopic = React.lazy(() => import('./pages/Admin/InterestTopics/Create'));
const EditInterestTopic = React.lazy(() => import('./pages/Admin/InterestTopics/Edit'));
const Brands = React.lazy(() => import('./pages/Admin/Brands'));
const NoOperationBrands = React.lazy(() => import('./pages/Admin/Brands/NoOperation'));
const CreateBrand = React.lazy(() => import('./pages/Admin/Brands/Create'));
const EditBrand = React.lazy(() => import('./pages/Admin/Brands/Edit'));
const RequestRecoveryPassword = React.lazy(() => import('./pages/RequestRecoveryPassword'));
const RecoveryPassword = React.lazy(() => import('./pages/RecoveryPassword'));
const Feed = React.lazy(() => import('./pages/Feed'));
const Favorites = React.lazy(() => import('./pages/Favorites'));
const BrandsFeed = React.lazy(() => import('./pages/Brands'));
const Analysis = React.lazy(() => import('./pages/Analysis'));
const Relatories = React.lazy(() => import('./pages/Admin/Reports'));
const NoOperationRelatories = React.lazy(() => import('./pages/Admin/Reports/NoOperation'));

function Router(): JSX.Element {
  return (
    <React.Suspense fallback={<> </>}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/onboarding" element={<Onboarding />}>
          <Route path="profile" element={<ProfileOnboarding />} />
          <Route path="interesttopics" element={<Auth roles={[CLIENT]}><InterestTopicsOnboarding /></Auth>} />
          <Route path="favoritebrands" element={<Auth roles={[CLIENT]}><BrandsOnboarding /></Auth>} />
          <Route path="keywords" element={<Auth roles={[CLIENT]}><KeywordsOnboarding /></Auth>} />
        </Route>
        <Route path="/" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><Base /></Auth>}>
          <Route path="feed" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><Feed /></Auth>} />
          <Route path="analysis" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><Analysis /></Auth>} />
          <Route path="brands" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><BrandsFeed /></Auth>} />
          <Route path="favorites" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><Favorites /></Auth>} />
          <Route path="tutorials" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><NotFound /></Auth>} />
          <Route path="curatorship" element={<Auth roles={[CURATOR, ADMIN]}><Curatorship /></Auth>} />
          <Route path="curatorship/media/:id" element={<Auth roles={[CURATOR, ADMIN]}><MediaAnalisys /></Auth>} />
          <Route path="curatorship/media/edit/:id" element={<Auth roles={[CURATOR, ADMIN]}><MediaEdit /></Auth>} />


          <Route path="admin" element={<Auth roles={[CLIENT_ADMIN, ADMIN]}><Admin /></Auth>}>
            <Route path="dashboard" element={<Auth roles={[ADMIN]}><NotFound /></Auth>} />
            <Route path="companies" element={<Auth roles={[ADMIN]}><Companies /></Auth>}>
              <Route path="" element={<Auth roles={[ADMIN]}><NoOperationCompanies /></Auth>} />
              <Route path="create" element={<Auth roles={[ADMIN]}><CreateCompany /></Auth>} />
              <Route path="edit/:id" element={<Auth roles={[ADMIN]}><EditCompany /></Auth>} />
              <Route path=":id/contracts" element={<Auth roles={[ADMIN]}><Contracts /></Auth>} />
              <Route path=":id/contracts/create" element={<Auth roles={[ADMIN]}><CreateContract /></Auth>} />
              <Route path=":id/contracts/edit/:contractId" element={<Auth roles={[ADMIN]}><EditContract /></Auth>} />
              <Route path=":id/contracts/users/:contractId" element={<Auth roles={[ADMIN]}><UsersContract /></Auth>} />
            </Route>
            <Route path="users" element={<Auth roles={[CLIENT, ADMIN]}><Users /></Auth>}>
              <Route path="" element={<Auth roles={[CLIENT, ADMIN]}><NoOperationUser /></Auth>} />
              <Route path="create" element={<Auth roles={[CLIENT, ADMIN]}><CreateUser /></Auth>} />
              <Route path="edit/:id" element={<Auth roles={[CLIENT, ADMIN]}><EditUser /></Auth>} />
              <Route path="invite" element={<Auth roles={[CLIENT, ADMIN]}><InviteUser /></Auth>} />
            </Route>

            <Route path="brandcategories" element={<Auth roles={[ADMIN]}><BrandCategories /></Auth>}>
              <Route path="" element={<Auth roles={[ADMIN]}><NoOperationBrandCategories /></Auth>} />
              <Route path="create" element={<Auth roles={[ADMIN]}><CreateBrandCategory /></Auth>} />
              <Route path="edit/:id" element={<Auth roles={[ADMIN]}><EditBrandCategory /></Auth>} />
            </Route>
            <Route path="brands" element={<Auth roles={[ADMIN]}><Brands /></Auth>}>
              <Route path="" element={<Auth roles={[ADMIN]}><NoOperationBrands /></Auth>} />
              <Route path="create" element={<Auth roles={[ADMIN]}><CreateBrand /></Auth>} />
              <Route path="edit/:id" element={<Auth roles={[ADMIN]}><EditBrand /></Auth>} />
            </Route>
            <Route path="tutorialcategories" element={<Auth roles={[ADMIN]}><TutorialCategories /></Auth>}>
              <Route path="" element={<Auth roles={[ADMIN]}><NoOperationTutorialCategories /></Auth>} />
              <Route path="create" element={<Auth roles={[ADMIN]}><CreateTutorialCategory /></Auth>} />
              <Route path="edit/:id" element={<Auth roles={[ADMIN]}><EditTutorialCategory /></Auth>} />
            </Route>
            <Route path="tutorials" element={<Auth roles={[ADMIN]}><TutorialsAdmin /></Auth>}>
              <Route path="" element={<Auth roles={[ADMIN]}><NoOperationTutorials /></Auth>} />
              <Route path="create" element={<Auth roles={[ADMIN]}><CreateTutorial /></Auth>} />
              <Route path="edit/:id" element={<Auth roles={[ADMIN]}><EditTutorial /></Auth>} />
            </Route>
            <Route path="interesttopics" element={<Auth roles={[ADMIN]}><InterestTopics /></Auth>}>
              <Route path="" element={<Auth roles={[ADMIN]}><NoOperationInterestTopics /></Auth>} />
              <Route path="create" element={<Auth roles={[ADMIN]}><CreateInterestTopic /></Auth>} />
              <Route path="edit/:id" element={<Auth roles={[ADMIN]}><EditInterestTopic /></Auth>} />
            </Route>
            <Route path="capturemodules" element={<Auth roles={[ADMIN]}><CaptureModules /></Auth>}>
              <Route path="" element={<Auth roles={[ADMIN]}><NoOperationCaptureModules /></Auth>} />
              <Route path="create" element={<Auth roles={[ADMIN]}><CreateCaptureModule /></Auth>} />
              <Route path="edit/:id" element={<Auth roles={[ADMIN]}><EditCaptureModule /></Auth>} />
              <Route path="edit/:id/questions" element={<Auth roles={[ADMIN]}><QuestionsCaptureModule /></Auth>} />
            </Route>
            <Route path="reports" element={<Auth roles={[ADMIN]}><Relatories /></Auth>}>
              <Route index element={<Auth roles={[ADMIN]}><NoOperationRelatories /></Auth>} />
            </Route>
            <Route path="notifications" element={<Auth roles={[ADMIN]}><NotFound /></Auth>} />
            <Route path="activatorpayments" element={<Auth roles={[ADMIN]}><NotFound /></Auth>} />
            <Route path="activators" element={<Auth roles={[ADMIN]}><NotFound /></Auth>} />
            <Route path="irregularmedias" element={<Auth roles={[ADMIN]}><NotFound /></Auth>} />
          </Route>
          <Route path="notifications" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><NotFound /></Auth>} />
          <Route path="profile" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><Profile /></Auth>}>
            <Route path="profile" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><ProfileProfile /></Auth>} />
            <Route path="topics" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><InterestTopicsProfile /></Auth>} />
            <Route path="brands" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><BrandsProfile /></Auth>} />
            <Route path="keywords" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><KeywordsProfile /></Auth>} />
            <Route path="" element={<Navigate to="/profile/profile" replace />} />
          </Route>

          <Route path="tutorials" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><Tutorials /></Auth>}>
            <Route path="/tutorials/:id" element={<Auth roles={[CLIENT, CLIENT_ADMIN, CURATOR, ADMIN]}><List /></Auth>} />
            <Route path="" element={<Navigate to="/tutorials/all" replace />} />
          </Route>
        </Route>
        <Route path="/requestrecoverypassword" element={<RequestRecoveryPassword />} />
        <Route path="/recoverypassword" element={<RecoveryPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Suspense>
  );
}
export default Router;
